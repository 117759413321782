"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyStore = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var EmptyStore = function () { return ((0, jsx_runtime_1.jsx)("svg", __assign({ className: "empty-store-icon", viewBox: "0 0 80 80", fill: "#CACCCC" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M15.9473 56.8698V43.822C14.7611 44.0856 13.575 44.2174 12.257 44.2174C11.4662 44.2174 10.8072 44.2174 10.0165 44.0856C9.3575 43.9538 8.56672 43.822 7.90774 43.6902V69.5222C7.90774 71.8945 9.75288 73.7397 11.9934 73.7397H66.5568L45.8649 56.8698H15.9473ZM12.257 40C15.5519 40 18.4514 38.682 20.6919 36.4415L3.1631 22.2075C-0.92257 29.1927 2.76771 38.8138 10.5437 40C11.0708 40 11.7298 40 12.257 40ZM79.341 66.7545L72.0923 60.8237V43.6902C71.4333 43.822 70.6425 44.0856 69.9835 44.0856C69.1927 44.2174 68.5338 44.2174 67.743 44.2174C66.425 44.2174 65.2389 44.0856 64.0527 43.822V54.2339L45.8649 39.4728C46.9193 39.8682 48.1054 40 49.1598 40C52.9819 40 56.145 38.2866 58.5173 35.6507C60.7578 38.2866 64.0527 40 67.743 40C68.2702 40 68.7974 40 69.4563 39.8682C77.2323 38.8138 81.0544 29.0609 76.8369 21.9439L68.6656 8.23719C68.0066 7.18282 66.6886 6.39205 65.2389 6.26025H14.7611C13.3114 6.39205 11.9934 7.18282 11.3344 8.23719L10.0165 10.3459L5.66722 6.78744C5.27183 6.52385 4.87644 6.39205 4.34926 6.39205C3.69028 6.39205 3.1631 6.65564 2.76771 7.18282L0.395387 10.4777C0.131796 10.8731 0 11.2685 0 11.7957C0 12.4547 0.263591 12.9818 0.658979 13.3772L74.3328 73.3443C74.7282 73.6079 75.1236 73.7397 75.6507 73.7397C76.3097 73.7397 76.8369 73.4761 77.2323 72.9489L79.6046 69.654C79.8682 69.2586 80 68.8632 80 68.336C80 67.6771 79.7364 67.1499 79.341 66.7545Z" }) }))); };
exports.EmptyStore = EmptyStore;
