"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentFail = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var components_1 = require("../../../components");
require("./index.scss");
var PaymentFail = function (_a) {
    var onGoBack = _a.onGoBack, user = _a.user;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "table-confirm-centered" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "table-fail" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "table-fail-container" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Navigation, { isLoggedIn: !!user, title: t('Payment Canceled'), className: "table-fail-navigation" }), (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ className: "pay-at-table-btn", onClick: onGoBack }, { children: t('Go back') }))] })) })) })));
};
exports.PaymentFail = PaymentFail;
