"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pickup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Pickup = function (props) { return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "20", height: "20", viewBox: "0 0 12 19", xmlns: "http://www.w3.org/2000/svg" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M9.59986 1.78125C9.59986 2.76465 8.79361 3.5625 7.79986 3.5625C6.80611 3.5625 5.99986 2.76465 5.99986 1.78125C5.99986 0.797852 6.80611 0 7.79986 0C8.79361 0 9.59986 0.797852 9.59986 1.78125ZM4.74361 7.3959C4.70611 7.41074 4.67236 7.42559 4.63486 7.44043L4.33486 7.57031C3.71986 7.84121 3.24736 8.35703 3.03361 8.98789L2.93611 9.27734C2.72611 9.90078 2.04736 10.2348 1.41736 10.027C0.78736 9.81914 0.44986 9.14746 0.65986 8.52402L0.75736 8.23457C1.18486 6.96914 2.12986 5.9375 3.35986 5.3957L3.65986 5.26582C4.43986 4.92441 5.28361 4.74629 6.13861 4.74629C7.81111 4.74629 9.31861 5.74082 9.95986 7.26602L10.5374 8.63535L11.3399 9.03242C11.9324 9.32558 12.1724 10.0381 11.8761 10.6244C11.5799 11.2107 10.8599 11.4482 10.2674 11.1551L9.26236 10.6615C8.87611 10.4686 8.57236 10.1494 8.40736 9.75234L8.04736 8.89883L7.32361 11.3295L9.17986 13.3334C9.38236 13.5523 9.52486 13.8158 9.59986 14.1053L10.4624 17.523C10.6236 18.1576 10.2336 18.8033 9.58861 18.9629C8.94361 19.1225 8.29486 18.7365 8.13361 18.0982L7.30861 14.8289L4.65736 11.9678C4.10236 11.3703 3.89611 10.5353 4.10611 9.75234L4.73986 7.3959H4.74361ZM2.57611 14.7695L3.51361 12.4539C3.59236 12.5652 3.68236 12.6691 3.77611 12.773L5.30236 14.4207L4.75861 15.7641C4.66861 15.9867 4.53361 16.1908 4.36111 16.3615L2.04736 18.6512C1.57861 19.115 0.81736 19.115 0.34861 18.6512C-0.12014 18.1873 -0.12014 17.434 0.34861 16.9701L2.57611 14.7695Z" }) }))); };
exports.Pickup = Pickup;
