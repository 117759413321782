"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Home = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var services_1 = require("../../services");
var hooks_1 = require("../../hooks");
var store_1 = require("../../store");
var utils_1 = require("../../utils");
var components_1 = require("../../components");
var Header_1 = require("./Header");
var Main_1 = require("./Main");
require("./index.scss");
var Home = function () {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = __read((0, react_1.useState)('pickup'), 2), orderType = _c[0], setOrderType = _c[1];
    var _d = services_1.ChainService.useGetChain(), stores = _d.stores, isChainLoading = _d.isChainLoading, logoLink = _d.logoLink, privacyLink = _d.privacyLink, termsLink = _d.termsLink, splashLink = _d.splashLink, contactDetails = _d.contactDetails, legalDetails = _d.legalDetails;
    var user = services_1.UserService.useGetUser().data;
    var coordinates = (0, hooks_1.useGetGeolocation)().coordinates;
    var latitude = (_a = coordinates === null || coordinates === void 0 ? void 0 : coordinates.coords) === null || _a === void 0 ? void 0 : _a.latitude;
    var longitude = (_b = coordinates === null || coordinates === void 0 ? void 0 : coordinates.coords) === null || _b === void 0 ? void 0 : _b.longitude;
    var isUserCoordinatesReady = !!(latitude && longitude);
    var sortedStoresByGeolocation = !isUserCoordinatesReady
        ? (0, utils_1.sortStoresByClosed)(stores)
        : (0, utils_1.sortStoresByGeolocation)(stores, longitude, latitude);
    var hasPickup = false;
    var hasDelivery = false;
    var pickupStores = [];
    var deliveryStores = [];
    var orderTypeTabs = [];
    for (var storeIndex = 0; storeIndex < sortedStoresByGeolocation.length; storeIndex++) {
        var store = stores[storeIndex];
        if (store.has_takeaway) {
            pickupStores.push(store);
            hasPickup = true;
        }
    }
    if (hasPickup) {
        orderTypeTabs.push('pickup');
    }
    if (hasDelivery) {
        orderTypeTabs.push('delivery');
    }
    var visibleStores = orderType === utils_1.ORDER_TYPES.pickup ? pickupStores : deliveryStores;
    var onChangeOrderType = function (selectedIndex) {
        setOrderType(orderTypeTabs[selectedIndex]);
        dispatch((0, store_1.clearCheckout)());
    };
    (0, react_1.useEffect)(function () {
        setOrderType(orderTypeTabs[0] || utils_1.ORDER_TYPES.pickup);
    }, [hasPickup, hasDelivery]);
    if (isChainLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "home-page" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "home-page-left" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "home-page-left-content" }, { children: [(0, jsx_runtime_1.jsx)(Header_1.Header, { logoLink: logoLink, privacyLink: privacyLink, termsLink: termsLink, splashLink: splashLink, contactDetails: contactDetails, legalDetails: legalDetails, isLoggedIn: !!user }), (0, jsx_runtime_1.jsx)(Main_1.Main, { visibleStores: visibleStores, orderTypeTabs: orderTypeTabs, logoLink: logoLink, coordinates: coordinates, orderType: orderType, onChangeOrderType: onChangeOrderType })] })) })), !!splashLink && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "home-page-right" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "home-page-right-content" }, { children: (0, jsx_runtime_1.jsx)("img", { className: "home-page-right-bg", src: splashLink, alt: "" }) })) })))] })));
};
exports.Home = Home;
