"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPaymentHistory = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var _1 = require("./");
var components_1 = require("../../components");
var UserPaymentHistory = function (_a) {
    var usePayments = _a.usePayments, currency = _a.currency;
    var t = (0, react_i18next_1.useTranslation)().t;
    var setFilters = (0, hooks_1.useSetFilters)();
    var onOpenPurchase = function (id) {
        setFilters({ profile_page: _1.PROFILE_PAGES.purchase, purchase_id: id });
    };
    if (usePayments.length === 0) {
        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-purchase-history" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-purchase-history-title" }, { children: t('Purchase history is empty') })) })));
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-purchase-history" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-purchase-history-title" }, { children: [t('Completed'), " (", usePayments.length, ")"] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-purchase-history-items" }, { children: usePayments.map(function (_a) {
                    var id = _a.id, storeName = _a.restaurant_name, total = _a.total, date = _a.created_at;
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ onClick: function () { return onOpenPurchase(id); }, className: "profile-page-purchase-history-items-item" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-purchase-history-items-item-left" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "profile-page-purchase-history-items-item-name" }, { children: storeName })), (0, jsx_runtime_1.jsxs)("span", { children: [date, " - ", currency, (0, utils_1.round)(total)] })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-purchase-history-items-item-right" }, { children: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "chevron-right" }) }))] }), id));
                }) }))] })));
};
exports.UserPaymentHistory = UserPaymentHistory;
