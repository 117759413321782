"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var MinusPrimary_1 = require("./icons-svg/MinusPrimary");
var MinusSecondary_1 = require("./icons-svg/MinusSecondary");
var PlusPrimary_1 = require("./icons-svg/PlusPrimary");
var PlusSecondary_1 = require("./icons-svg/PlusSecondary");
var Back_1 = require("./icons-svg/Back");
var Pencil_1 = require("./icons-svg/Pencil");
var Trash_1 = require("./icons-svg/Trash");
var ChevronDown_1 = require("./icons-svg/ChevronDown");
var ChevronRight_1 = require("./icons-svg/ChevronRight");
var Pending_1 = require("./icons-svg/Pending");
var Present_1 = require("./icons-svg/Present");
var FileDone_1 = require("./icons-svg/FileDone");
var Cooking_1 = require("./icons-svg/Cooking");
var PersonWalking_1 = require("./icons-svg/PersonWalking");
var RegStar_1 = require("./icons-svg/RegStar");
var Star_1 = require("./icons-svg/Star");
var Close_1 = require("./icons-svg/Close");
var Search_1 = require("./icons-svg/Search");
var FlagAR_1 = require("./icons-svg/FlagAR");
var FlagARM_1 = require("./icons-svg/FlagARM");
var FlagRU_1 = require("./icons-svg/FlagRU");
var FlagUK_1 = require("./icons-svg/FlagUK");
var Pickup_1 = require("./icons-svg/Pickup");
var Delivery_1 = require("./icons-svg/Delivery");
var AddressMarker_1 = require("./icons-svg/AddressMarker");
var Error_1 = require("./icons-svg/Error");
var PersonContact_1 = require("./icons-svg/PersonContact");
var Email_1 = require("./icons-svg/Email");
var Notes_1 = require("./icons-svg/Notes");
var Cutlery_1 = require("./icons-svg/Cutlery");
var Phone_1 = require("./icons-svg/Phone");
var CalendarFill_1 = require("./icons-svg/CalendarFill");
var Bike_1 = require("./icons-svg/Bike");
var Check_1 = require("./icons-svg/Check");
var CardPay_1 = require("./icons-svg/CardPay");
var Idram_1 = require("./icons-svg/Idram");
var ApplePay_1 = require("./icons-svg/ApplePay");
var Person_1 = require("./icons-svg/Person");
var EmptyStore_1 = require("./icons-svg/EmptyStore");
var EllipseSharp_1 = require("./icons-svg/EllipseSharp");
var Login_1 = require("./icons-svg/Login");
var LogoutIcon_1 = require("./icons-svg/LogoutIcon");
var LoyaltyIcon_1 = require("./icons-svg/LoyaltyIcon");
var PersonalInfoIcon_1 = require("./icons-svg/PersonalInfoIcon");
var PurchaseHistoryIcon_1 = require("./icons-svg/PurchaseHistoryIcon");
var RemoveX_1 = require("./icons-svg/RemoveX");
var Danger_1 = require("./icons-svg/Danger");
var CheckedCircle_1 = require("./icons-svg/CheckedCircle");
var LocationOn_1 = require("./icons-svg/LocationOn");
var OutlinePhone_1 = require("./icons-svg/OutlinePhone");
var TimeFive_1 = require("./icons-svg/TimeFive");
var MapIcon_1 = require("./icons-svg/MapIcon");
var IconSBP_1 = require("./icons-svg/IconSBP");
var Icon = function (_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    switch (name) {
        case 'pickup':
            return (0, jsx_runtime_1.jsx)(Pickup_1.Pickup, __assign({}, props));
        case 'delivery':
            return (0, jsx_runtime_1.jsx)(Delivery_1.Delivery, __assign({}, props));
        case 'minus-primary':
            return (0, jsx_runtime_1.jsx)(MinusPrimary_1.MinusPrimary, __assign({}, props));
        case 'minus-secondary':
            return (0, jsx_runtime_1.jsx)(MinusSecondary_1.MinusSecondary, __assign({}, props));
        case 'plus-primary':
            return (0, jsx_runtime_1.jsx)(PlusPrimary_1.PlusPrimary, __assign({}, props));
        case 'plus-secondary':
            return (0, jsx_runtime_1.jsx)(PlusSecondary_1.PlusSecondary, __assign({}, props));
        case 'back':
            return (0, jsx_runtime_1.jsx)(Back_1.Back, __assign({}, props));
        case 'pencil':
            return (0, jsx_runtime_1.jsx)(Pencil_1.Pencil, __assign({}, props));
        case 'trash':
            return (0, jsx_runtime_1.jsx)(Trash_1.Trash, __assign({}, props));
        case 'chevron-down':
            return (0, jsx_runtime_1.jsx)(ChevronDown_1.ChevronDown, __assign({}, props));
        case 'chevron-right':
            return (0, jsx_runtime_1.jsx)(ChevronRight_1.ChevronRight, __assign({}, props));
        case 'remove-x':
            return (0, jsx_runtime_1.jsx)(RemoveX_1.RemoveX, __assign({}, props));
        case 'danger':
            return (0, jsx_runtime_1.jsx)(Danger_1.Danger, __assign({}, props));
        case 'checked-circle':
            return (0, jsx_runtime_1.jsx)(CheckedCircle_1.CheckedCircle, __assign({}, props));
        case 'pending':
            return (0, jsx_runtime_1.jsx)(Pending_1.Pending, __assign({}, props));
        case 'present':
            return (0, jsx_runtime_1.jsx)(Present_1.Present, __assign({}, props));
        case 'cooking':
            return (0, jsx_runtime_1.jsx)(Cooking_1.Cooking, __assign({}, props));
        case 'file-done':
            return (0, jsx_runtime_1.jsx)(FileDone_1.FileDone, __assign({}, props));
        case 'person-walking':
            return (0, jsx_runtime_1.jsx)(PersonWalking_1.PersonWalking, __assign({}, props));
        case 'person-contact':
            return (0, jsx_runtime_1.jsx)(PersonContact_1.PersonContact, __assign({}, props));
        case 'reg-star':
            return (0, jsx_runtime_1.jsx)(RegStar_1.RegStar, __assign({}, props));
        case 'star':
            return (0, jsx_runtime_1.jsx)(Star_1.Star, __assign({}, props));
        case 'close':
            return (0, jsx_runtime_1.jsx)(Close_1.Close, __assign({}, props));
        case 'search':
            return (0, jsx_runtime_1.jsx)(Search_1.Search, __assign({}, props));
        case 'ar':
            return (0, jsx_runtime_1.jsx)(FlagAR_1.FlagAR, __assign({}, props));
        case 'hy':
            return (0, jsx_runtime_1.jsx)(FlagARM_1.FlagARM, __assign({}, props));
        case 'ru':
            return (0, jsx_runtime_1.jsx)(FlagRU_1.FlagRU, __assign({}, props));
        case 'en':
            return (0, jsx_runtime_1.jsx)(FlagUK_1.FlagUK, __assign({}, props));
        case 'address-marker':
            return (0, jsx_runtime_1.jsx)(AddressMarker_1.AddressMarker, __assign({}, props));
        case 'error':
            return (0, jsx_runtime_1.jsx)(Error_1.Error, __assign({}, props));
        case 'email':
            return (0, jsx_runtime_1.jsx)(Email_1.Email, __assign({}, props));
        case 'phone':
            return (0, jsx_runtime_1.jsx)(Phone_1.Phone, __assign({}, props));
        case 'calendar-fill':
            return (0, jsx_runtime_1.jsx)(CalendarFill_1.CalendarFill, __assign({}, props));
        case 'bike':
            return (0, jsx_runtime_1.jsx)(Bike_1.Bike, __assign({}, props));
        case 'check':
            return (0, jsx_runtime_1.jsx)(Check_1.Check, __assign({}, props));
        case 'card-pay':
            return (0, jsx_runtime_1.jsx)(CardPay_1.CardPay, __assign({}, props));
        case 'idram':
            return (0, jsx_runtime_1.jsx)(Idram_1.Idram, __assign({}, props));
        case 'apple-pay':
            return (0, jsx_runtime_1.jsx)(ApplePay_1.ApplePay, __assign({}, props));
        case 'person':
            return (0, jsx_runtime_1.jsx)(Person_1.Person, __assign({}, props));
        case 'notes':
            return (0, jsx_runtime_1.jsx)(Notes_1.Notes, __assign({}, props));
        case 'cutlery':
            return (0, jsx_runtime_1.jsx)(Cutlery_1.Cutlery, __assign({}, props));
        case 'empty-store':
            return (0, jsx_runtime_1.jsx)(EmptyStore_1.EmptyStore, __assign({}, props));
        case 'ellipse-sharp':
            return (0, jsx_runtime_1.jsx)(EllipseSharp_1.EllipseSharp, __assign({}, props));
        case 'location-on':
            return (0, jsx_runtime_1.jsx)(LocationOn_1.LocationOn, __assign({}, props));
        case 'outline-phone':
            return (0, jsx_runtime_1.jsx)(OutlinePhone_1.OutlinePhone, __assign({}, props));
        case 'logout':
            return (0, jsx_runtime_1.jsx)(LogoutIcon_1.LogoutIcon, __assign({}, props));
        case 'login':
            return (0, jsx_runtime_1.jsx)(Login_1.Login, __assign({}, props));
        case 'loyalty':
            return (0, jsx_runtime_1.jsx)(LoyaltyIcon_1.LoyaltyIcon, __assign({}, props));
        case 'personal-info':
            return (0, jsx_runtime_1.jsx)(PersonalInfoIcon_1.PersonalInfoIcon, __assign({}, props));
        case 'purchase-history':
            return (0, jsx_runtime_1.jsx)(PurchaseHistoryIcon_1.PurchaseHistoryIcon, __assign({}, props));
        case 'time-five':
            return (0, jsx_runtime_1.jsx)(TimeFive_1.TimeFive, __assign({}, props));
        case 'map':
            return (0, jsx_runtime_1.jsx)(MapIcon_1.MapIcon, __assign({}, props));
        case 'sbp':
            return (0, jsx_runtime_1.jsx)(IconSBP_1.IconSBP, __assign({}, props));
        default:
            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
};
exports.Icon = Icon;
__exportStar(require("./IconCycle"), exports);
