"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonalInfoIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PersonalInfoIcon = function () { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "15", cy: "15", r: "15", fill: "#F9FAFB" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.6667 10.8333C11.6667 9.94928 12.0179 9.10143 12.643 8.47631C13.2681 7.85119 14.1159 7.5 15 7.5C15.8841 7.5 16.7319 7.85119 17.357 8.47631C17.9821 9.10143 18.3333 9.94928 18.3333 10.8333C18.3333 11.7174 17.9821 12.5652 17.357 13.1904C16.7319 13.8155 15.8841 14.1667 15 14.1667C14.1159 14.1667 13.2681 13.8155 12.643 13.1904C12.0179 12.5652 11.6667 11.7174 11.6667 10.8333ZM11.6667 15.8333C10.5616 15.8333 9.50179 16.2723 8.72039 17.0537C7.93899 17.8351 7.5 18.8949 7.5 20C7.5 20.663 7.76339 21.2989 8.23223 21.7678C8.70107 22.2366 9.33696 22.5 10 22.5H20C20.663 22.5 21.2989 22.2366 21.7678 21.7678C22.2366 21.2989 22.5 20.663 22.5 20C22.5 18.8949 22.061 17.8351 21.2796 17.0537C20.4982 16.2723 19.4384 15.8333 18.3333 15.8333H11.6667Z", fill: "black" })] }))); };
exports.PersonalInfoIcon = PersonalInfoIcon;
