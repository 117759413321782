"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetails = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var hooks_1 = require("../../../hooks");
require("./index.scss");
var ProductDetails = function (_a) {
    var productDetailsRef = _a.productDetailsRef;
    var t = (0, react_i18next_1.useTranslation)().t;
    var product = (0, hooks_1.useSelector)(function (state) { return state.product; });
    var productName = product.title;
    var productAllergens = product.allergens || [];
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-details", ref: productDetailsRef }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "product-page-details-title" }, { children: productName })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-details-utilities" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "product-page-details-utilities-allergens" }, { children: productAllergens.map(function (_a) {
                            var name = _a.name, id = _a.id;
                            return (0, jsx_runtime_1.jsx)("span", { children: name }, id);
                        }) })), !!product.weight && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-details-utilities-info" }, { children: [product.weight, " ", (0, jsx_runtime_1.jsx)("span", { children: t('productDetails.weight') })] }))), !!product.calories && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-details-utilities-info" }, { children: [product.calories, " ", t('productDetails.calories')] })))] })), (0, jsx_runtime_1.jsx)("p", __assign({ className: "product-page-details-description" }, { children: product === null || product === void 0 ? void 0 : product.description }))] })));
};
exports.ProductDetails = ProductDetails;
