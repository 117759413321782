"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModifierMultipleSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var store_1 = require("../../store");
var MultipleSelectPrimary_1 = require("../MultipleSelectPrimary");
require("./index.scss");
var ModifierMultipleSelect = function (_a) {
    var modifierItem = _a.modifierItem, modifierCategoryIndex = _a.modifierCategoryIndex, modifierItemIndex = _a.modifierItemIndex, isPlusDisabled = _a.isPlusDisabled;
    var dispatch = (0, react_redux_1.useDispatch)();
    var modifierItemQuantity = modifierItem.quantity || 0;
    var isMinusDisabled = modifierItemQuantity <= 0;
    var onIncreaseModifierQuantity = function () {
        dispatch((0, store_1.increaseProductModifierQuantity)({
            modifierCategoryIndex: modifierCategoryIndex,
            modifierItemIndex: modifierItemIndex,
        }));
    };
    var onDecreaseModifierQuantity = function () {
        dispatch((0, store_1.decreaseProductModifierQuantity)({
            modifierCategoryIndex: modifierCategoryIndex,
            modifierItemIndex: modifierItemIndex,
        }));
    };
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(MultipleSelectPrimary_1.MultipleSelectPrimary, { quantity: modifierItemQuantity, isMinusDisabled: isMinusDisabled, isPlusDisabled: isPlusDisabled, onClickPlus: onIncreaseModifierQuantity, onClickMinus: onDecreaseModifierQuantity }) }));
};
exports.ModifierMultipleSelect = ModifierMultipleSelect;
