"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trash = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Trash = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 18 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M3.16663 5.25H15.8333M7.91663 8.25V12.75M11.0833 8.25V12.75M3.95829 5.25L4.74996 14.25C4.74996 14.6478 4.91677 15.0294 5.21371 15.3107C5.51064 15.592 5.91337 15.75 6.33329 15.75H12.6666C13.0866 15.75 13.4893 15.592 13.7862 15.3107C14.0831 15.0294 14.25 14.6478 14.25 14.25L15.0416 5.25M7.12496 5.25V3C7.12496 2.80109 7.20837 2.61032 7.35683 2.46967C7.5053 2.32902 7.70666 2.25 7.91663 2.25H11.0833C11.2933 2.25 11.4946 2.32902 11.6431 2.46967C11.7916 2.61032 11.875 2.80109 11.875 3V5.25", stroke: "black", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
exports.Trash = Trash;
