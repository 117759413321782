"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripePaymentTabs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var react_i18next_1 = require("react-i18next");
var store_1 = require("../../../../store");
var components_1 = require("../../../../components");
var utils_1 = require("../../../../utils");
var hooks_1 = require("../../../../hooks");
var StripePaymentTabs = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
    var onChangeTab = function (paymentTab) { return dispatch((0, store_1.changePaymentTab)(paymentTab)); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.PaymentMethod, { onChange: onChangeTab, paymentTab: utils_1.PAYMENT_TABS.stripeAppleGoogle, tabText: t('Apple Pay'), isActive: paymentTab === utils_1.PAYMENT_TABS.stripeAppleGoogle, icon: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "apple-pay" }) }), (0, jsx_runtime_1.jsx)(components_1.PaymentMethod, { onChange: onChangeTab, paymentTab: utils_1.PAYMENT_TABS.stripeCard, tabText: t('Card'), isActive: paymentTab === utils_1.PAYMENT_TABS.stripeCard, icon: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "card-pay" }) })] }));
};
exports.StripePaymentTabs = StripePaymentTabs;
