"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Phone = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Phone = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 25 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M20.7812 21.875C18.6111 21.875 16.467 21.4017 14.349 20.4552C12.2309 19.5087 10.3038 18.1677 8.56771 16.4323C6.8316 14.6962 5.49062 12.7691 4.54479 10.651C3.59896 8.53299 3.12569 6.38889 3.125 4.21875C3.125 3.90625 3.22917 3.64583 3.4375 3.4375C3.64583 3.22917 3.90625 3.125 4.21875 3.125H8.4375C8.68056 3.125 8.89757 3.20764 9.08854 3.37292C9.27951 3.53819 9.39236 3.73333 9.42708 3.95833L10.1042 7.60417C10.1389 7.88194 10.1302 8.11632 10.0781 8.30729C10.026 8.49826 9.93055 8.66319 9.79167 8.80208L7.26562 11.3542C7.61285 11.9965 8.025 12.617 8.50208 13.2156C8.97917 13.8142 9.50451 14.3917 10.0781 14.9479C10.6163 15.4861 11.1806 15.9854 11.7708 16.4458C12.3611 16.9062 12.9861 17.3271 13.6458 17.7083L16.0937 15.2604C16.25 15.1042 16.4542 14.9868 16.7062 14.9083C16.9583 14.8299 17.2056 14.8083 17.4479 14.8438L21.0417 15.5729C21.2847 15.6424 21.4844 15.7684 21.6406 15.951C21.7969 16.1337 21.875 16.3375 21.875 16.5625V20.7812C21.875 21.0938 21.7708 21.3542 21.5625 21.5625C21.3542 21.7708 21.0938 21.875 20.7812 21.875ZM6.27604 9.375L7.99479 7.65625L7.55208 5.20833H5.23438C5.32118 5.92014 5.44271 6.62326 5.59896 7.31771C5.75521 8.01215 5.9809 8.69792 6.27604 9.375ZM15.599 18.6979C16.276 18.9931 16.9663 19.2274 17.6698 19.401C18.3733 19.5747 19.0806 19.6875 19.7917 19.7396V17.4479L17.3437 16.9531L15.599 18.6979Z", fill: "black" }) })));
};
exports.Phone = Phone;
