"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = void 0;
var react_query_1 = require("@tanstack/react-query");
var react_router_1 = require("react-router");
var ua_parser_js_1 = require("ua-parser-js");
var react_redux_1 = require("react-redux");
var react_toastify_1 = require("react-toastify");
var store_1 = require("../../store");
var utils_1 = require("../../utils");
var httpClientAccount_1 = require("../httpClientAccount");
var AuthService = (function () {
    function AuthService() {
    }
    AuthService.useLogout = function () {
        var dispatch = (0, react_redux_1.useDispatch)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var clearUserBindingsInfo = function () {
            dispatch((0, store_1.changeSaveCard)(false));
            dispatch((0, store_1.changeAmeriaCardBindingUuid)(''));
        };
        var _a = (0, react_query_1.useMutation)(['logout'], function () { return httpClientAccount_1.httpClientAccount.post('/logout'); }, {
            onSuccess: function () {
                localStorage.removeItem('user:token');
                queryClient.removeQueries(['user']);
                clearUserBindingsInfo();
                window.location.reload();
            },
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        }), logout = _a.mutate, isLogoutLoading = _a.isLoading;
        return {
            logout: logout,
            isLogoutLoading: isLogoutLoading,
        };
    };
    AuthService.useAuth = function () {
        var _this = this;
        var state = (0, react_router_1.useLocation)().state;
        var dispatch = (0, react_redux_1.useDispatch)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var isIos = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
        var isAndroid = navigator.userAgent.match(/(android)/i);
        var userAgent = new ua_parser_js_1.UAParser().getResult();
        var device_model = userAgent.device.vendor || 'unknown device';
        var platform_v = "".concat(userAgent.browser.name, " ").concat(userAgent.browser.version);
        var device_id = (0, utils_1.stringToHash)([device_model, platform_v, userAgent.ua, userAgent.os.name || ''].join(''));
        var app_v = VERSION || '';
        var platform = '';
        if (isIos) {
            platform = 'ios';
        }
        else if (isAndroid) {
            platform = 'android';
        }
        else {
            platform = 'desktop';
        }
        return (0, react_query_1.useMutation)(['user-auth', utils_1.chainId], function (_a) {
            var phone = _a.phone, name = _a.name, email = _a.email, code = _a.code, payment_key = _a.payment_key;
            return __awaiter(_this, void 0, void 0, function () {
                var body, res;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            body = {
                                chain_id: utils_1.chainId,
                                phone: phone,
                                name: name,
                                email: email,
                                code: code,
                                platform: platform,
                                app_v: app_v,
                                device_id: device_id,
                                device_model: device_model,
                                platform_v: platform_v,
                            };
                            if (payment_key) {
                                body.payment_key = payment_key;
                            }
                            return [4, httpClientAccount_1.httpClientAccount.post('/auth', body)];
                        case 1:
                            res = _b.sent();
                            return [2, res.data];
                    }
                });
            });
        }, {
            onSuccess: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStorage.setItem('user:token', data.token);
                            if (state === null || state === void 0 ? void 0 : state.saveCard) {
                                dispatch((0, store_1.changeSaveCard)(true));
                            }
                            return [4, queryClient.invalidateQueries(['user'])];
                        case 1:
                            _a.sent();
                            return [4, queryClient.invalidateQueries(['payment'])];
                        case 2:
                            _a.sent();
                            (0, utils_1.timeout)(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4, queryClient.invalidateQueries(['user'])];
                                        case 1:
                                            _a.sent();
                                            return [4, queryClient.invalidateQueries(['payment'])];
                                        case 2:
                                            _a.sent();
                                            return [2];
                                    }
                                });
                            }); }, 5000);
                            return [2];
                    }
                });
            }); },
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        });
    };
    AuthService.useSendAuthCode = function () {
        return (0, react_query_1.useMutation)(['send-auth-code', utils_1.chainId], function (_a) {
            var chain_id = _a.chain_id, phone = _a.phone;
            return httpClientAccount_1.httpClientAccount
                .post('/code', {
                chain_id: chain_id,
                phone: phone,
            })
                .then(function (res) { return res.data; });
        }, {
            onError: function (error) {
                var _a, _b;
                if ((_a = error.data) === null || _a === void 0 ? void 0 : _a.message) {
                    react_toastify_1.toast.error((_b = error.data) === null || _b === void 0 ? void 0 : _b.message);
                }
            },
        });
    };
    return AuthService;
}());
exports.AuthService = AuthService;
__exportStar(require("./types"), exports);
