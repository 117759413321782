"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var MapIcon = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ stroke: "currentColor", strokeWidth: "0", height: size, width: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M15 19.9231L9 17.8231L4 19.7631V5.78115L9 4.07715L15 6.17715L20 4.23715V18.2771L15 19.9231ZM14.5 18.7031V7.00315L9.5 5.25815V16.9581L14.5 18.7031Z", fill: "black" }) })));
};
exports.MapIcon = MapIcon;
