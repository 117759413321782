"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.productSliceReducer = exports.clearProduct = exports.changeWarningModifierId = exports.checkModifierRadioItem = exports.uncheckModifierItem = exports.checkModifierItem = exports.decreaseProductModifierQuantity = exports.increaseProductModifierQuantity = exports.decreaseProductQuantity = exports.increaseProductQuantity = exports.changeProduct = exports.productSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    id: 0,
    title: '',
    description: '',
    photo: {
        url: '',
        thumb: '',
        big_thumb_url: '',
    },
    allergens: [],
    modifier_groups: [],
    weight: 0,
    calories: 0,
    stock_limit: 0,
    stock_status: '',
    price: 0,
    quantity: 1,
    warningModifierId: null,
    vat: null,
};
exports.productSlice = (0, toolkit_1.createSlice)({
    name: 'product',
    initialState: initialState,
    reducers: {
        changeProduct: function (state, action) {
            var isBasketProduct = action.payload.isBasketProduct;
            return __assign(__assign({}, action.payload.product), { quantity: isBasketProduct ? action.payload.product.quantity : 1, warningModifierId: null });
        },
        increaseProductQuantity: function (state) {
            state.quantity = state.quantity + 1;
        },
        decreaseProductQuantity: function (state) {
            state.quantity = state.quantity - 1;
        },
        increaseProductModifierQuantity: function (state, action) {
            var modifierCategory = state.modifier_groups[action.payload.modifierCategoryIndex];
            var modifierItem = modifierCategory.modifier_items[action.payload.modifierItemIndex];
            var modifierItemQuantity = modifierItem.quantity || 0;
            modifierItem.quantity = modifierItemQuantity + 1;
            modifierItem.checked = true;
        },
        decreaseProductModifierQuantity: function (state, action) {
            var modifierCategory = state.modifier_groups[action.payload.modifierCategoryIndex];
            var modifierItem = modifierCategory.modifier_items[action.payload.modifierItemIndex];
            modifierItem.quantity = modifierItem.quantity - 1;
            modifierItem.checked = modifierItem.quantity > 0;
        },
        checkModifierItem: function (state, action) {
            var modifierCategory = state.modifier_groups[action.payload.modifierCategoryIndex];
            var modifierItem = modifierCategory.modifier_items[action.payload.modifierItemIndex];
            modifierItem.checked = true;
        },
        uncheckModifierItem: function (state, action) {
            var modifierCategory = state.modifier_groups[action.payload.modifierCategoryIndex];
            var modifierItem = modifierCategory.modifier_items[action.payload.modifierItemIndex];
            modifierItem.checked = false;
        },
        checkModifierRadioItem: function (state, action) {
            var modifierCategory = state.modifier_groups[action.payload.modifierCategoryIndex];
            modifierCategory.modifier_items = modifierCategory.modifier_items.map(function (modifierItem, index) {
                modifierItem.checked = action.payload.modifierItemIndex === index && !modifierItem.checked;
                return modifierItem;
            });
        },
        changeWarningModifierId: function (state, action) {
            state.warningModifierId = action.payload;
        },
        clearProduct: function () { return initialState; },
    },
});
exports.changeProduct = (_a = exports.productSlice.actions, _a.changeProduct), exports.increaseProductQuantity = _a.increaseProductQuantity, exports.decreaseProductQuantity = _a.decreaseProductQuantity, exports.increaseProductModifierQuantity = _a.increaseProductModifierQuantity, exports.decreaseProductModifierQuantity = _a.decreaseProductModifierQuantity, exports.checkModifierItem = _a.checkModifierItem, exports.uncheckModifierItem = _a.uncheckModifierItem, exports.checkModifierRadioItem = _a.checkModifierRadioItem, exports.changeWarningModifierId = _a.changeWarningModifierId, exports.clearProduct = _a.clearProduct;
exports.productSliceReducer = exports.productSlice.reducer;
