"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModifierRadioSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var store_1 = require("../../store");
require("./index.scss");
var ModifierRadioSelect = function (_a) {
    var modifierItemId = _a.modifierItemId, modifierCategoryIndex = _a.modifierCategoryIndex, modifierItemIndex = _a.modifierItemIndex, isModifierItemChecked = _a.isModifierItemChecked, isRadio = _a.isRadio;
    var dispatch = (0, react_redux_1.useDispatch)();
    var onChangeModifier = function () {
        if (isRadio) {
            dispatch((0, store_1.checkModifierRadioItem)({ modifierCategoryIndex: modifierCategoryIndex, modifierItemIndex: modifierItemIndex }));
            return;
        }
        if (isModifierItemChecked) {
            dispatch((0, store_1.uncheckModifierItem)({ modifierCategoryIndex: modifierCategoryIndex, modifierItemIndex: modifierItemIndex }));
        }
        else {
            dispatch((0, store_1.checkModifierItem)({ modifierCategoryIndex: modifierCategoryIndex, modifierItemIndex: modifierItemIndex }));
        }
    };
    return ((0, jsx_runtime_1.jsx)("input", { className: "modifier-item-radio-select", type: "radio", name: "label".concat(modifierItemId), id: "label".concat(modifierItemId), checked: isModifierItemChecked, onClick: onChangeModifier }));
};
exports.ModifierRadioSelect = ModifierRadioSelect;
