"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuService = void 0;
var react_query_1 = require("@tanstack/react-query");
var httpClient_1 = require("../httpClient");
var react_router_1 = require("react-router");
var hooks_1 = require("../../hooks");
var MenuService = (function () {
    function MenuService() {
    }
    MenuService.useGetMenus = function () {
        var storeId = (0, react_router_1.useParams)().storeId;
        var _a = (0, react_query_1.useQuery)(['menus', storeId], function () { return (0, httpClient_1.getData)("/menus", { params: { restaurant_id: storeId } }); }), _b = _a.data, menus = _b === void 0 ? [] : _b, isMenusLoading = _a.isInitialLoading;
        return {
            menus: menus,
            isMenusLoading: isMenusLoading
        };
    };
    MenuService.useGetCategories = function () {
        var _a;
        var storeId = (0, react_router_1.useParams)().storeId;
        var orderType = (0, hooks_1.useSelector)(function (state) { return state.checkout.orderType; });
        var _b = this.useGetMenus(), menus = _b.menus, isMenusLoading = _b.isMenusLoading;
        var menuId = (_a = menus[0]) === null || _a === void 0 ? void 0 : _a.id;
        var _c = (0, react_query_1.useQuery)(['categories', storeId], function () {
            return (0, httpClient_1.getData)('/menus/get', {
                params: { id: menuId, restaurant_id: storeId, type: orderType }
            });
        }, {
            enabled: !!menuId
        }), _d = _c.data, categories = _d === void 0 ? [] : _d, isCategoriesLoading = _c.isInitialLoading;
        var navigationCategories = categories.map(function (category) { return category.name; });
        return {
            categories: categories,
            isCategoriesLoading: isMenusLoading || isCategoriesLoading,
            navigationCategories: navigationCategories
        };
    };
    return MenuService;
}());
exports.MenuService = MenuService;
__exportStar(require("./types"), exports);
