"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Error = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Error = function (_a) {
    var _b = _a.width, width = _b === void 0 ? 16 : _b;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ stroke: "currentColor", fill: "#c86836", strokeWidth: "0", viewBox: "0 0 24 24", height: width, width: width, xmlns: "http://www.w3.org/2000/svg" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" }) })));
};
exports.Error = Error;
