"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressMarker = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var AddressMarker = function () {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("g", __assign({ clipPath: "url(#clip0_375_1944)" }, { children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.75 9.00023C18.7531 10.4466 18.2885 11.8551 17.4255 13.0157L12 20.2502L6.5745 13.0157C5.93926 12.1575 5.51668 11.1608 5.34148 10.1075C5.16629 9.05422 5.24346 7.9744 5.56667 6.95673C5.88988 5.93906 6.44991 5.01259 7.20075 4.25342C7.95159 3.49424 8.87182 2.92402 9.88585 2.58959C10.8999 2.25516 11.9788 2.16606 13.0339 2.32962C14.0891 2.49317 15.0904 2.90472 15.9556 3.53044C16.8208 4.15616 17.5252 4.97822 18.011 5.92908C18.4968 6.87994 18.75 7.93248 18.75 9.00023ZM21 9.00023C21 11.0102 20.3415 12.8642 19.23 14.3612L13.4055 22.1252L12 24.0002L10.593 22.1252L4.77 14.3612C3.92203 13.2174 3.35756 11.8886 3.12298 10.4842C2.8884 9.07976 2.99039 7.6397 3.42058 6.28235C3.85078 4.92499 4.59689 3.68908 5.59762 2.67616C6.59835 1.66323 7.82514 0.902207 9.17718 0.455608C10.5292 0.00900816 11.9679 -0.110419 13.3751 0.107139C14.7823 0.324697 16.1178 0.873031 17.2718 1.70709C18.4259 2.54114 19.3655 3.63712 20.0137 4.90497C20.6618 6.17281 20.9998 7.57634 21 9.00023ZM12 12.0002C12.7957 12.0002 13.5587 11.6842 14.1213 11.1216C14.6839 10.5589 15 9.79588 15 9.00023C15 8.20458 14.6839 7.44152 14.1213 6.87891C13.5587 6.3163 12.7957 6.00023 12 6.00023C11.2044 6.00023 10.4413 6.3163 9.87868 6.87891C9.31607 7.44152 9 8.20458 9 9.00023C9 9.79588 9.31607 10.5589 9.87868 11.1216C10.4413 11.6842 11.2044 12.0002 12 12.0002Z", fill: "black" }) })), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", __assign({ id: "clip0_375_1944" }, { children: (0, jsx_runtime_1.jsx)("rect", { width: "24", height: "24", fill: "white" }) })) })] })));
};
exports.AddressMarker = AddressMarker;
