"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoresMapModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Modal_1 = require("../Modal");
var Icons_1 = require("../../Icons");
var StoresMap_1 = require("../../StoresMap");
require("./index.scss");
var StoresMapModal = function (_a) {
    var isOpen = _a.isOpen, userCoordinates = _a.userCoordinates, stores = _a.stores, logoLink = _a.logoLink, onClose = _a.onClose, onOpenStore = _a.onOpenStore;
    return ((0, jsx_runtime_1.jsxs)(Modal_1.Modal, __assign({ className: "stores-map-modal", isOpen: isOpen, onRequestClose: onClose }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "stores-map-close", onClick: onClose }, { children: (0, jsx_runtime_1.jsx)(Icons_1.IconCycle, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "back" }) }) })), (0, jsx_runtime_1.jsx)(StoresMap_1.StoresMap, { userCoordinates: userCoordinates, stores: stores, onOpenStore: onOpenStore, logoLink: logoLink })] })));
};
exports.StoresMapModal = StoresMapModal;
