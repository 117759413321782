"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarFill = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var CalendarFill = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 26 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 26 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M22.75 13V20.5833C22.75 21.158 22.5217 21.7091 22.1154 22.1154C21.7091 22.5217 21.158 22.75 20.5833 22.75H5.41667C4.84203 22.75 4.29093 22.5217 3.8846 22.1154C3.47827 21.7091 3.25 21.158 3.25 20.5833V13H22.75ZM17.3333 3.25C17.6207 3.25 17.8962 3.36414 18.0994 3.5673C18.3025 3.77047 18.4167 4.04602 18.4167 4.33333V5.41667H20.5833C21.158 5.41667 21.7091 5.64494 22.1154 6.05127C22.5217 6.4576 22.75 7.0087 22.75 7.58333V10.8333H3.25V7.58333C3.25 7.0087 3.47827 6.4576 3.8846 6.05127C4.29093 5.64494 4.84203 5.41667 5.41667 5.41667H7.58333V4.33333C7.58333 4.04602 7.69747 3.77047 7.90063 3.5673C8.1038 3.36414 8.37935 3.25 8.66667 3.25C8.95398 3.25 9.22953 3.36414 9.4327 3.5673C9.63586 3.77047 9.75 4.04602 9.75 4.33333V5.41667H16.25V4.33333C16.25 4.04602 16.3641 3.77047 16.5673 3.5673C16.7705 3.36414 17.046 3.25 17.3333 3.25Z", fill: "black" }) })));
};
exports.CalendarFill = CalendarFill;
