"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Main = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@headlessui/react");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../utils");
var components_1 = require("../../../components");
var Stores_1 = require("./Stores");
var OrderTypeTabs_1 = require("./OrderTypeTabs");
var PickupDatesTabs_1 = require("./PickupDatesTabs");
require("./index.scss");
var Main = function (_a) {
    var visibleStores = _a.visibleStores, logoLink = _a.logoLink, coordinates = _a.coordinates, orderType = _a.orderType, orderTypeTabs = _a.orderTypeTabs, onChangeOrderType = _a.onChangeOrderType;
    var t = (0, react_i18next_1.useTranslation)().t;
    var isStoresEmpty = visibleStores.length === 0;
    var isSingleOrderType = orderTypeTabs.length === 1;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "home-page-main" }, { children: !isStoresEmpty ? ((0, jsx_runtime_1.jsxs)(react_1.Tab.Group, __assign({ onChange: onChangeOrderType }, { children: [!isSingleOrderType && (0, jsx_runtime_1.jsx)(OrderTypeTabs_1.OrderTypeTabs, { orderTypeTabs: orderTypeTabs }), orderType === utils_1.ORDER_TYPES.pickup && ((0, jsx_runtime_1.jsx)(PickupDatesTabs_1.PickupDatesTabs, { isSingleOrderType: isSingleOrderType })), !isStoresEmpty && ((0, jsx_runtime_1.jsx)(Stores_1.Stores, { logoLink: logoLink, userCoordinates: coordinates, type: orderType, stores: visibleStores, isSingleOrderType: isSingleOrderType }))] }))) : ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "empty-store" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Icon, { name: "empty-store" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "empty-store-header" }, { children: t('Oh no, none of our stores are available') })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "empty-store-hint" }, { children: t('Please come back another time') }))] }))) })));
};
exports.Main = Main;
