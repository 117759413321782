"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cutlery = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Cutlery = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 25 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 17 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M2.91675 8.99984V16.4998C2.91675 16.7209 2.99577 16.9328 3.13642 17.0891C3.27707 17.2454 3.46784 17.3332 3.66675 17.3332H4.41675C4.61566 17.3332 4.80643 17.2454 4.94708 17.0891C5.08773 16.9328 5.16675 16.7209 5.16675 16.4998V8.99984M2.91675 3.99984V0.666504M5.16675 3.99984V0.666504M7.41675 5.6665V1.49984C7.41675 1.27882 7.33773 1.06686 7.19708 0.910582C7.05643 0.754301 6.86566 0.666504 6.66675 0.666504H1.41675C1.21784 0.666504 1.02707 0.754301 0.886418 0.910582C0.745766 1.06686 0.666748 1.27882 0.666748 1.49984V5.6665C0.666748 6.55056 0.982819 7.39841 1.54543 8.02353C2.10804 8.64865 2.8711 8.99984 3.66675 8.99984H4.41675C5.2124 8.99984 5.97546 8.64865 6.53807 8.02353C7.10068 7.39841 7.41675 6.55056 7.41675 5.6665ZM10.4167 12.3332V0.666504H12.6667C13.4624 0.666504 14.2255 1.01769 14.7881 1.64281C15.3507 2.26794 15.6667 3.11578 15.6667 3.99984V12.3332H12.6667V16.4998C12.6667 16.7209 12.5877 16.9328 12.4471 17.0891C12.3064 17.2454 12.1157 17.3332 11.9167 17.3332H11.1667C10.9678 17.3332 10.7771 17.2454 10.6364 17.0891C10.4958 16.9328 10.4167 16.7209 10.4167 16.4998V12.3332Z", stroke: "black", strokeLinecap: "round", strokeLinejoin: "round" }) })));
};
exports.Cutlery = Cutlery;
