"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = exports.PROFILE_PAGES = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_router_1 = require("react-router");
var utils_1 = require("../../utils");
var services_1 = require("../../services");
var hooks_1 = require("../../hooks");
var components_1 = require("../../components");
var NotAuthorized_1 = require("./NotAuthorized");
var ProfileHome_1 = require("./ProfileHome");
var ProfileEdit_1 = require("./ProfileEdit");
var ProfileBindings_1 = require("./ProfileBindings");
var UserPaymentHistory_1 = require("./UserPaymentHistory");
var UserPayment_1 = require("./UserPayment");
require("./index.scss");
exports.PROFILE_PAGES = {
    home: 'home',
    edit: 'edit',
    bindings: 'bindings',
    purchaseHistory: 'purchase_history',
    purchase: 'purchase',
};
var Profile = function () {
    var _a, _b, _c;
    var t = (0, react_i18next_1.useTranslation)().t;
    var history = (0, react_router_1.useHistory)();
    var setFilters = (0, hooks_1.useSetFilters)();
    var profilePageQuery = (0, utils_1.parseQuery)('profile_page');
    var purchaseIdQuery = (0, utils_1.parseQuery)('purchase_id');
    var profilePage = profilePageQuery || exports.PROFILE_PAGES.home;
    var _d = services_1.UserService.useGetUser(), user = _d.data, isUserLoading = _d.isInitialLoading, isError = _d.isError;
    var _e = services_1.ChainService.useGetChain(), chain = _e.chain, isChainLoading = _e.isChainLoading;
    var _f = services_1.UserService.useGetBindings(!!user), cards = _f.cards, isBindingsLoading = _f.isBindingsLoading, isCardsEmpty = _f.isCardsEmpty;
    var _g = services_1.UserService.useGetUserPayments(!!user && profilePage === exports.PROFILE_PAGES.purchaseHistory), userPayments = _g.userPayments, isUserPaymentsLoading = _g.isUserPaymentsLoading;
    var _h = services_1.UserService.useGetUserPayment(Number(purchaseIdQuery)), userPayment = _h.userPayment, isUserPaymentLoading = _h.isUserPaymentLoading;
    var onGoBack = function () {
        if (history.length > 2) {
            history.goBack();
            return;
        }
        if (!user || !profilePageQuery) {
            history.replace(history.location.pathname);
            return;
        }
        if (profilePage === exports.PROFILE_PAGES.purchase && purchaseIdQuery) {
            setFilters({ profile_page: exports.PROFILE_PAGES.purchaseHistory, purchase_id: '' }, true);
            return;
        }
        if (profilePageQuery) {
            setFilters({ profile_page: '' }, true);
            return;
        }
    };
    if (isUserLoading ||
        isChainLoading ||
        isBindingsLoading ||
        isUserPaymentsLoading ||
        isUserPaymentLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    var content;
    var title;
    switch (true) {
        case isError || !user:
            content = (0, jsx_runtime_1.jsx)(NotAuthorized_1.NotAuthorized, { chain: chain });
            title = 'Sign in/up';
            break;
        case profilePage === exports.PROFILE_PAGES.home:
            content = ((0, jsx_runtime_1.jsx)(ProfileHome_1.ProfileHome, { cashbackSettings: chain.cashback_settings, chainName: chain.name, user: user, currency: (_a = chain.currency) === null || _a === void 0 ? void 0 : _a.title, isCardsEmpty: isCardsEmpty }));
            title = 'Profile';
            break;
        case profilePage === exports.PROFILE_PAGES.edit:
            content = (0, jsx_runtime_1.jsx)(ProfileEdit_1.ProfileEdit, { user: user });
            title = 'Edit';
            break;
        case profilePage === exports.PROFILE_PAGES.bindings:
            content = (0, jsx_runtime_1.jsx)(ProfileBindings_1.ProfileBindings, { cards: cards });
            title = 'Bindings';
            break;
        case profilePage === exports.PROFILE_PAGES.purchaseHistory:
            content = ((0, jsx_runtime_1.jsx)(UserPaymentHistory_1.UserPaymentHistory, { usePayments: userPayments, currency: (_b = chain.currency) === null || _b === void 0 ? void 0 : _b.title }));
            title = 'Payment History';
            break;
        case !!(profilePage === exports.PROFILE_PAGES.purchase && purchaseIdQuery):
            content = (0, jsx_runtime_1.jsx)(UserPayment_1.UserPayment, { currency: chain.currency.title, userPayment: userPayment });
            title = "".concat(t('Payment'), " #").concat(purchaseIdQuery);
            break;
        default:
            content = ((0, jsx_runtime_1.jsx)(ProfileHome_1.ProfileHome, { cashbackSettings: chain.cashback_settings, chainName: chain.name, user: user, currency: (_c = chain.currency) === null || _c === void 0 ? void 0 : _c.title, isCardsEmpty: isCardsEmpty }));
            title = 'Profile';
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-container" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-header" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "profile-page-header-left" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-header-left-back", onClick: onGoBack }, { children: (0, jsx_runtime_1.jsx)(components_1.IconCycle, { children: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "back" }) }) })), (0, jsx_runtime_1.jsx)("h1", __assign({ className: "profile-page-header-left-title", onClick: onGoBack }, { children: t(title) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "profile-page-header-right" }, { children: (0, jsx_runtime_1.jsx)(components_1.Languages, {}) }))] })), content] })) })));
};
exports.Profile = Profile;
