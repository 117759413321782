"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Back = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Back = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? 'black' : _b, _c = _a.size, size = _c === void 0 ? 40 : _c, props = __rest(_a, ["fill", "size"]);
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: size, height: size, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "20", cy: "20", r: "20", fill: "#ffffff" }), (0, jsx_runtime_1.jsx)("path", { d: "M22.2893 29.4734C22.0422 29.4741 21.798 29.4295 21.5746 29.3428C21.3513 29.2562 21.1546 29.1297 20.9989 28.9727L13.008 20.8532C12.7646 20.611 12.6316 20.3073 12.6316 19.9938C12.6316 19.6804 12.7646 19.3767 13.008 19.1345L21.2801 11.015C21.5609 10.7386 21.9645 10.5648 22.402 10.5318C22.8394 10.4989 23.275 10.6094 23.6129 10.8391C23.9507 11.0688 24.1632 11.3988 24.2035 11.7567C24.2439 12.1145 24.1088 12.4708 23.828 12.7472L16.4326 20.0006L23.5798 27.2541C23.7821 27.4527 23.9106 27.6946 23.9501 27.9511C23.9896 28.2076 23.9385 28.468 23.8027 28.7015C23.667 28.9349 23.4523 29.1317 23.1841 29.2685C22.9159 29.4053 22.6054 29.4764 22.2893 29.4734Z", fill: "black" })] })));
};
exports.Back = Back;
