"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("../../utils");
require("./index.scss");
var SummaryItem = function (_a) {
    var color = _a.color, text = _a.text, amount = _a.amount, currency = _a.currency, fontSize = _a.fontSize, fontWeight = _a.fontWeight, icon = _a.icon, margin = _a.margin, _b = _a.isMinusAmount, isMinusAmount = _b === void 0 ? false : _b, _c = _a.isHidden, isHidden = _c === void 0 ? false : _c;
    if (isHidden)
        return null;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "summary-item", style: { color: color, fontSize: fontSize, fontWeight: fontWeight, margin: margin } }, { children: [(0, jsx_runtime_1.jsxs)("span", __assign({ className: "summary-item-left" }, { children: [text, icon] })), (0, jsx_runtime_1.jsxs)("span", __assign({ className: "summary-item-right" }, { children: [isMinusAmount && '-', currency, (0, utils_1.round)(amount)] }))] })));
};
exports.SummaryItem = SummaryItem;
