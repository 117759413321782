"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Person = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Person = function (_a) {
    var props = __rest(_a, []);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M9.99999 2.77637C11.3737 2.77637 12.4868 3.88953 12.4868 5.26321C12.4868 6.63689 11.3737 7.75005 9.99999 7.75005C8.62631 7.75005 7.51315 6.63689 7.51315 5.26321C7.51315 3.88953 8.62631 2.77637 9.99999 2.77637ZM9.99999 13.4343C13.5171 13.4343 17.2237 15.1632 17.2237 15.9211V17.2237H2.77631V15.9211C2.77631 15.1632 6.48289 13.4343 9.99999 13.4343ZM9.99999 0.526367C7.38288 0.526367 5.26315 2.6461 5.26315 5.26321C5.26315 7.88031 7.38288 10.0001 9.99999 10.0001C12.6171 10.0001 14.7368 7.88031 14.7368 5.26321C14.7368 2.6461 12.6171 0.526367 9.99999 0.526367ZM9.99999 11.1843C6.83815 11.1843 0.526306 12.7711 0.526306 15.9211V19.4737H19.4737V15.9211C19.4737 12.7711 13.1618 11.1843 9.99999 11.1843Z", fill: "black" }) })));
};
exports.Person = Person;
