"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapImage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("../../../utils");
require("./index.scss");
var MapImage = function (_a) {
    var point = _a.point;
    if (!point) {
        return null;
    }
    var pointA = point === null || point === void 0 ? void 0 : point.lat;
    var pointB = point === null || point === void 0 ? void 0 : point.lng;
    if (!pointA || !pointB) {
        return null;
    }
    var mapSrc = "https://maps.googleapis.com/maps/api/staticmap?center=".concat(pointA, ",").concat(pointB, "&format=jpeg&zoom=18&size=820x280&maptype=roadmap&markers=size:tiny=color:red%7C").concat(pointA, ",").concat(pointB, "&key=").concat(utils_1.GOOGLE_MAPS_API_KEY);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-map" }, { children: (0, jsx_runtime_1.jsx)("img", { className: "checkout-page-map-image", src: mapSrc, alt: "" }) })));
};
exports.MapImage = MapImage;
