"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Email = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Email = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 25 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M21.875 4.6875H3.125C2.7106 4.6875 2.31317 4.85212 2.02015 5.14515C1.72712 5.43817 1.5625 5.8356 1.5625 6.25V18.75C1.5625 19.1644 1.72712 19.5618 2.02015 19.8549C2.31317 20.1479 2.7106 20.3125 3.125 20.3125H21.875C22.2894 20.3125 22.6868 20.1479 22.9799 19.8549C23.2729 19.5618 23.4375 19.1644 23.4375 18.75V6.25C23.4375 5.8356 23.2729 5.43817 22.9799 5.14515C22.6868 4.85212 22.2894 4.6875 21.875 4.6875ZM20.1562 6.25L12.5 11.5469L4.84375 6.25H20.1562ZM3.125 18.75V6.96094L12.0547 13.1406C12.1855 13.2313 12.3408 13.28 12.5 13.28C12.6592 13.28 12.8145 13.2313 12.9453 13.1406L21.875 6.96094V18.75H3.125Z", fill: "black" }) })));
};
exports.Email = Email;
