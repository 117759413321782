"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayByGoogleApple = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_stripe_js_1 = require("@stripe/react-stripe-js");
var react_toastify_1 = require("react-toastify");
var services_1 = require("../../services");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var Button_1 = require("../Button");
var react_router_1 = require("react-router");
var react_i18next_1 = require("react-i18next");
var PayByGoogleApple = function (_a) {
    var stripe = _a.stripe, isValidFields = _a.isValidFields, onValidate = _a.onValidate;
    var t = (0, react_i18next_1.useTranslation)().t;
    var history = (0, react_router_1.useHistory)();
    var _b = __read((0, react_1.useState)(null), 2), paymentRequest = _b[0], setPaymentRequest = _b[1];
    var _c = __read((0, react_1.useState)(false), 2), isProcessing = _c[0], setIsProcessing = _c[1];
    var _d = services_1.PaymentService.usePreparePayment(), pay = _d.pay, request = _d.request;
    var total = (0, hooks_1.useGetAmounts)().total;
    var store = (0, hooks_1.useGetConfigs)().store;
    var chain = store.chain || {};
    var storeName = chain.name;
    var country = chain.country.iso2 || 'GB';
    var currency = (chain.currency.code || '').toLowerCase();
    var amount = Number((Number((0, utils_1.round)(total)) * 100).toFixed(0));
    (0, react_1.useEffect)(function () {
        if (stripe && !paymentRequest) {
            var pr_1 = stripe.paymentRequest({
                country: country,
                currency: currency,
                total: {
                    label: storeName,
                    amount: amount
                },
                requestPayerName: true,
                requestPayerEmail: true
            });
            pr_1.canMakePayment()
                .then(function (result) {
                if (result) {
                    setPaymentRequest(pr_1);
                }
            })
                .catch(function (err) {
                react_toastify_1.toast.error(err);
            });
        }
    }, [stripe]);
    (0, react_1.useEffect)(function () {
        if (paymentRequest && amount) {
            paymentRequest.update({
                total: {
                    label: storeName,
                    amount: amount
                }
            });
        }
    }, [paymentRequest, amount]);
    if (stripe && paymentRequest) {
        if (paymentRequest.hasRegisteredListener('paymentmethod')) {
            paymentRequest.off('paymentmethod');
        }
        paymentRequest.on('paymentmethod', function (ev) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                pay(request, {
                    onSuccess: function (piData) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a, paymentIntent, confirmError, error;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!piData.client_secret) {
                                        ev.complete('fail');
                                        return [2];
                                    }
                                    setIsProcessing(true);
                                    return [4, stripe.confirmCardPayment(piData.client_secret, { payment_method: ev.paymentMethod.id }, { handleActions: false })];
                                case 1:
                                    _a = _b.sent(), paymentIntent = _a.paymentIntent, confirmError = _a.error;
                                    if (!confirmError) return [3, 2];
                                    ev.complete('fail');
                                    setIsProcessing(false);
                                    react_toastify_1.toast.error(confirmError);
                                    return [3, 5];
                                case 2:
                                    ev.complete('success');
                                    if (!((paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.status) === 'requires_action')) return [3, 4];
                                    return [4, stripe.confirmCardPayment(piData.client_secret)];
                                case 3:
                                    error = (_b.sent()).error;
                                    if (error) {
                                        setIsProcessing(false);
                                    }
                                    else {
                                        setIsProcessing(false);
                                        history.push("/store/confirm/".concat(piData.key));
                                    }
                                    return [3, 5];
                                case 4:
                                    setIsProcessing(false);
                                    history.push("/store/confirm/".concat(piData.key));
                                    _b.label = 5;
                                case 5: return [2];
                            }
                        });
                    }); },
                    onError: function (error) {
                        var _a;
                        setIsProcessing(false);
                        react_toastify_1.toast.error((_a = error.data) === null || _a === void 0 ? void 0 : _a.message);
                    }
                });
                return [2];
            });
        }); });
        if (isProcessing) {
            return (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ disabled: true }, { children: t('Place Order') }));
        }
        else {
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: { marginTop: 30, visibility: isValidFields ? 'visible' : 'hidden' } }, { children: (0, jsx_runtime_1.jsx)(react_stripe_js_1.PaymentRequestButtonElement, { options: { paymentRequest: paymentRequest }, className: "checkout-total-button" }) })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ style: { visibility: isValidFields ? 'hidden' : 'visible' }, onClick: onValidate }, { children: t('Place Order') }))] }));
        }
    }
    return null;
};
exports.PayByGoogleApple = PayByGoogleApple;
