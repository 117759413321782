"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Terms = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../../utils");
require("./index.scss");
var Terms = function (_a) {
    var privacyLink = _a.privacyLink, termsLink = _a.termsLink;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-terms" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-terms-header" }, { children: [(0, jsx_runtime_1.jsx)("span", { children: t('Pay securely with') }), " ", (0, jsx_runtime_1.jsx)("img", { src: "/icons/".concat(utils_1.appDomain, ".svg"), alt: "" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-terms-content" }, { children: [t('agreement.byClicking'), ' ', (0, jsx_runtime_1.jsx)("a", __assign({ href: privacyLink, target: "_blank" }, { children: t('agreement.agree') })), ' ', (0, jsx_runtime_1.jsx)("a", __assign({ href: termsLink, target: "_blank" }, { children: t('agreement.termsOfUse', { platform: t("".concat(utils_1.appDomain)) }) }))] }))] })));
};
exports.Terms = Terms;
