"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonContact = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PersonContact = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 25 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: size, height: size, viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M4.16675 18.7502C4.16675 17.6451 4.60573 16.5853 5.38714 15.8039C6.16854 15.0225 7.22835 14.5835 8.33341 14.5835H16.6667C17.7718 14.5835 18.8316 15.0225 19.613 15.8039C20.3944 16.5853 20.8334 17.6451 20.8334 18.7502C20.8334 19.3027 20.6139 19.8326 20.2232 20.2233C19.8325 20.614 19.3026 20.8335 18.7501 20.8335H6.25008C5.69755 20.8335 5.16764 20.614 4.77694 20.2233C4.38624 19.8326 4.16675 19.3027 4.16675 18.7502Z", stroke: "black", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M12.5 10.4167C14.2259 10.4167 15.625 9.01764 15.625 7.29175C15.625 5.56586 14.2259 4.16675 12.5 4.16675C10.7741 4.16675 9.375 5.56586 9.375 7.29175C9.375 9.01764 10.7741 10.4167 12.5 10.4167Z", stroke: "black" })] })));
};
exports.PersonContact = PersonContact;
