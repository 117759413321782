"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlusPrimary = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PlusPrimary = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 24 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 23 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M17.25 12.998H12.4584V17.998C12.4584 18.2633 12.3574 18.5176 12.1777 18.7052C11.9979 18.8927 11.7542 18.998 11.5 18.998C11.2459 18.998 11.0021 18.8927 10.8224 18.7052C10.6427 18.5176 10.5417 18.2633 10.5417 17.998V12.998H5.75002C5.49585 12.998 5.2521 12.8927 5.07238 12.7052C4.89265 12.5176 4.79169 12.2633 4.79169 11.998C4.79169 11.7328 4.89265 11.4785 5.07238 11.2909C5.2521 11.1034 5.49585 10.998 5.75002 10.998H10.5417V5.99805C10.5417 5.73283 10.6427 5.47848 10.8224 5.29094C11.0021 5.1034 11.2459 4.99805 11.5 4.99805C11.7542 4.99805 11.9979 5.1034 12.1777 5.29094C12.3574 5.47848 12.4584 5.73283 12.4584 5.99805V10.998H17.25C17.5042 10.998 17.7479 11.1034 17.9277 11.2909C18.1074 11.4785 18.2084 11.7328 18.2084 11.998C18.2084 12.2633 18.1074 12.5176 17.9277 12.7052C17.7479 12.8927 17.5042 12.998 17.25 12.998Z", fill: "black" }) })));
};
exports.PlusPrimary = PlusPrimary;
