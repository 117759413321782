"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchOnMap = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var Icons_1 = require("../Icons");
require("./index.scss");
var SearchOnMap = function (_a) {
    var onOpenMap = _a.onOpenMap;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "search-on-map", onClick: onOpenMap }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "search-on-map-left" }, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "map", size: 24 }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "search-on-map-center" }, { children: t('Search on map') })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "search-on-map-right" }, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "chevron-down", size: 24 }) }))] })));
};
exports.SearchOnMap = SearchOnMap;
