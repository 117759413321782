"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronRight = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ChevronRight = function () { return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "31", height: "31", viewBox: "0 0 31 31", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M13.2527 6.1999C13.4954 6.19922 13.7351 6.24302 13.9543 6.32808C14.1735 6.41314 14.3667 6.5373 14.5195 6.69142L22.3639 14.6621C22.6028 14.8998 22.7334 15.198 22.7334 15.5057C22.7334 15.8134 22.6028 16.1115 22.3639 16.3492L14.2434 24.3199C13.9678 24.5912 13.5716 24.7618 13.1422 24.7942C12.7127 24.8266 12.2851 24.7181 11.9534 24.4926C11.6218 24.2671 11.4132 23.9431 11.3736 23.5918C11.334 23.2405 11.4666 22.8908 11.7423 22.6195L19.002 15.499L11.9859 8.37855C11.7873 8.18355 11.6612 7.9461 11.6224 7.6943C11.5836 7.44249 11.6338 7.18687 11.7671 6.95767C11.9003 6.72848 12.1111 6.53531 12.3744 6.40102C12.6377 6.26672 12.9425 6.19693 13.2527 6.1999Z", fill: "black" }) }))); };
exports.ChevronRight = ChevronRight;
