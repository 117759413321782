"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnClickOutside = void 0;
var react_1 = require("react");
var useOnClickOutside = function (ref, callback, eventType) {
    if (eventType === void 0) { eventType = 'touchstart'; }
    (0, react_1.useEffect)(function () {
        var handler = function (e) {
            var el = ref === null || ref === void 0 ? void 0 : ref.current;
            if (!el || el.contains(e.target)) {
                return;
            }
            callback(e);
        };
        document.addEventListener(eventType, handler);
        return function () {
            document.removeEventListener(eventType, handler);
        };
    }, [ref.current]);
};
exports.useOnClickOutside = useOnClickOutside;
