"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripePayment = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_stripe_js_1 = require("@stripe/react-stripe-js");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var PayByGoogleApple_1 = require("./PayByGoogleApple");
var PayByCard_1 = require("./PayByCard");
var StripePayment = function (_a) {
    var isValidFields = _a.isValidFields, onValidate = _a.onValidate;
    var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
    var stripe = (0, react_stripe_js_1.useStripe)();
    var elements = (0, react_stripe_js_1.useElements)();
    if (!stripe)
        return null;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [paymentTab === utils_1.PAYMENT_TABS.stripeCard && ((0, jsx_runtime_1.jsx)(PayByCard_1.PayByCard, { isValidFields: isValidFields, onValidate: onValidate, stripe: stripe, elements: elements })), paymentTab === utils_1.PAYMENT_TABS.stripeAppleGoogle && ((0, jsx_runtime_1.jsx)(PayByGoogleApple_1.PayByGoogleApple, { isValidFields: isValidFields, onValidate: onValidate, stripe: stripe }))] }));
};
exports.StripePayment = StripePayment;
