"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Text = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Text = function (_a) {
    var children = _a.children, color = _a.color, _b = _a.fontSize, fontSize = _b === void 0 ? 16 : _b, _c = _a.fontWeight, fontWeight = _c === void 0 ? 400 : _c, padding = _a.padding, margin = _a.margin, className = _a.className, _d = _a.as, as = _d === void 0 ? 'span' : _d, _e = _a.display, display = _e === void 0 ? 'inline' : _e, props = __rest(_a, ["children", "color", "fontSize", "fontWeight", "padding", "margin", "className", "as", "display"]);
    var Component = as;
    return ((0, jsx_runtime_1.jsx)(Component, __assign({ style: __assign({ color: color, fontSize: fontSize, fontWeight: fontWeight, padding: padding, margin: margin, display: display }, props), className: className }, { children: children })));
};
exports.Text = Text;
