"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.uiSliceReducer = exports.changeSearchValue = exports.changeSearchModal = exports.uiSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    isSearchModalOpened: false,
    search: ''
};
exports.uiSlice = (0, toolkit_1.createSlice)({
    name: 'ui',
    initialState: initialState,
    reducers: {
        changeSearchModal: function (state, action) {
            state.isSearchModalOpened = action.payload;
        },
        changeSearchValue: function (state, action) {
            state.search = action.payload;
        }
    }
});
exports.changeSearchModal = (_a = exports.uiSlice.actions, _a.changeSearchModal), exports.changeSearchValue = _a.changeSearchValue;
exports.uiSliceReducer = exports.uiSlice.reducer;
