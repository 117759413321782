"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Catalog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var Banner_1 = require("./Banner");
var Navigation_1 = require("./Navigation");
var Categories_1 = require("./Categories");
var services_1 = require("../../services");
var hooks_1 = require("../../hooks");
var components_1 = require("../../components");
require("./index.scss");
var Catalog = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = (0, hooks_1.useGetConfigs)(), currency = _a.currency, isStoreLoading = _a.isStoreLoading;
    var onGoToCheckoutPage = (0, hooks_1.useNavigate)().onGoToCheckoutPage;
    var basketProducts = (0, hooks_1.useSelector)(function (state) { return state.checkout.products; });
    var basketProductsQuantity = basketProducts.reduce(function (acc, product) { return acc + product.quantity; }, 0);
    var isBasketEmpty = basketProducts.length === 0;
    var _b = services_1.MenuService.useGetCategories(), categories = _b.categories, navigationCategories = _b.navigationCategories, isCategoriesLoading = _b.isCategoriesLoading;
    var _c = services_1.ChainService.useGetChain(), banners = _c.banners, isChainLoading = _c.isChainLoading;
    var _d = (0, hooks_1.useCatalogNavigation)(), categoriesRef = _d.categoriesRef, catalogNavigationRef = _d.catalogNavigationRef, catalogNavigationOffsetLefts = _d.catalogNavigationOffsetLefts, onClickOnCategory = _d.onClickOnCategory;
    if (isStoreLoading || isChainLoading || isCategoriesLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "catalog-page" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "catalog-page-content" }, { children: [(0, jsx_runtime_1.jsx)(components_1.HeaderNavigation, { showBackButton: false }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "catalog-page-container" }, { children: [(0, jsx_runtime_1.jsx)(Banner_1.Banner, { banners: banners }), (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { categoriesRef: categoriesRef, isBannersHidden: (banners === null || banners === void 0 ? void 0 : banners.length) === 0, catalogNavigationRef: catalogNavigationRef, catalogNavigationOffsetLefts: catalogNavigationOffsetLefts, navigationCategories: navigationCategories, onClickOnCategory: onClickOnCategory }), (0, jsx_runtime_1.jsx)(Categories_1.Categories, { currency: currency, categories: categories, categoriesRef: categoriesRef }), !isBasketEmpty && ((0, jsx_runtime_1.jsx)(components_1.FooterFixedContainer, __assign({ className: "footer-buttons" }, { children: (0, jsx_runtime_1.jsx)(components_1.Button, __assign({ fontWeight: "bold", className: "catalog-page-footer-button", onClick: onGoToCheckoutPage }, { children: (0, jsx_runtime_1.jsxs)("span", { children: [t('Checkout'), " (", basketProductsQuantity, ")"] }) })) })))] }))] })) })));
};
exports.Catalog = Catalog;
