"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModifierCategory = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ModifierCategoryHeader_1 = require("../ModifierCategoryHeader");
var ModifierCategoryItems_1 = require("../ModifierCategoryItems");
require("./index.scss");
var ModifierCategory = function (_a) {
    var modifier = _a.modifier, modifierCategoryIndex = _a.modifierCategoryIndex;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "modifier-category" }, { children: [(0, jsx_runtime_1.jsx)(ModifierCategoryHeader_1.ModifierCategoryHeader, { modifier: modifier }), (0, jsx_runtime_1.jsx)(ModifierCategoryItems_1.ModifierCategoryItems, { modifier: modifier, modifierCategoryIndex: modifierCategoryIndex })] })));
};
exports.ModifierCategory = ModifierCategory;
