"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreValidateWrapper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_router_1 = require("react-router");
var react_i18next_1 = require("react-i18next");
var react_redux_1 = require("react-redux");
var utils_1 = require("../../utils");
var hooks_1 = require("../../hooks");
var store_1 = require("../../store");
var ErrorWrapper_1 = require("../ErrorWrapper");
require("./index.scss");
var StoreValidateWrapper = function (_a) {
    var children = _a.children;
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var storeId = (0, react_router_1.useParams)().storeId;
    var cachedOrderType = (0, hooks_1.useSelector)(function (state) { return state.checkout.orderType; });
    var cachedStoreId = (0, hooks_1.useSelector)(function (state) { return state.checkout.cachedStoreId; });
    var tableNumber = (0, utils_1.parseQuery)('table');
    var orderType = (0, utils_1.parseQuery)('type');
    var isDineIn = tableNumber || orderType === utils_1.ORDER_TYPES.dinein;
    var isPickup = orderType === utils_1.ORDER_TYPES.pickup;
    var isDelivery = orderType === utils_1.ORDER_TYPES.delivery;
    var isValidOrderType = isDineIn || isPickup || isDelivery;
    (0, react_1.useEffect)(function () {
        if (isDineIn && cachedStoreId !== Number(storeId)) {
            dispatch((0, store_1.clearCheckout)());
        }
        else if (!isDineIn &&
            (cachedOrderType !== orderType || cachedStoreId !== Number(storeId))) {
            dispatch((0, store_1.clearCheckout)());
        }
        if (isDineIn && tableNumber) {
            dispatch((0, store_1.changeTableNumber)(tableNumber));
        }
        else {
            dispatch((0, store_1.changeTableNumber)(''));
        }
        if (!orderType && tableNumber) {
            dispatch((0, store_1.changeOrderType)('dinein'));
            return;
        }
        if (orderType && isValidOrderType) {
            dispatch((0, store_1.changeOrderType)(orderType));
        }
    }, [tableNumber, orderType, cachedOrderType]);
    if (!isValidOrderType) {
        return (0, jsx_runtime_1.jsx)(ErrorWrapper_1.ErrorWrapper, { errorTitle: t('Wrong Order Type') });
    }
    if (isDineIn && !tableNumber) {
        return (0, jsx_runtime_1.jsx)(ErrorWrapper_1.ErrorWrapper, { errorTitle: t('No Table Number!') });
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "app-wrapper" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "app-wrapper-content" }, { children: children })) })));
};
exports.StoreValidateWrapper = StoreValidateWrapper;
