"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductService = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_query_1 = require("@tanstack/react-query");
var react_router_1 = require("react-router");
var store_1 = require("../../store");
var httpClient_1 = require("../httpClient");
var ProductService = (function () {
    function ProductService() {
    }
    ProductService.useGetProduct = function (productId) {
        var dispatch = (0, react_redux_1.useDispatch)();
        var historyState = (0, react_router_1.useLocation)().state;
        var basketProduct = historyState === null || historyState === void 0 ? void 0 : historyState.basketProduct;
        var _a = (0, react_query_1.useQuery)(['product', productId], function () { return (0, httpClient_1.getData)('dishes/get', { params: { id: productId } }); }), _b = _a.data, product = _b === void 0 ? {} : _b, isProductLoading = _a.isInitialLoading;
        (0, react_1.useEffect)(function () {
            if (!isProductLoading) {
                if (basketProduct) {
                    dispatch((0, store_1.changeProduct)({ product: basketProduct, isBasketProduct: true }));
                }
                else {
                    dispatch((0, store_1.changeProduct)({ product: product }));
                }
            }
        }, [product, basketProduct, isProductLoading]);
        return {
            product: product,
            isProductLoading: isProductLoading
        };
    };
    ProductService.useFetchProduct = function () {
        var _a = (0, react_query_1.useMutation)(['product-fetch'], function (_a) {
            var productId = _a.productId;
            return (0, httpClient_1.getData)('dishes/get', { params: { id: productId } });
        }), fetchProduct = _a.mutate, isProductFetching = _a.isLoading;
        return {
            fetchProduct: fetchProduct,
            isProductFetching: isProductFetching
        };
    };
    return ProductService;
}());
exports.ProductService = ProductService;
__exportStar(require("./types"), exports);
