"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Header = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../utils");
var Header = function (_a) {
    var orderId = _a.orderId, orderType = _a.orderType, pickupAt = _a.pickupAt, tableNumber = _a.tableNumber;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "table-success-header" }, { children: [(0, jsx_runtime_1.jsxs)("span", __assign({ className: "table-success-header-id" }, { children: [t('Your Order ID'), ": ", (0, jsx_runtime_1.jsx)("strong", { children: orderId })] })), orderType === utils_1.ORDER_TYPES.pickup && pickupAt && ((0, jsx_runtime_1.jsxs)("span", __assign({ className: "table-success-header-type" }, { children: [t('Pickup at'), ": ", pickupAt] }))), orderType === utils_1.ORDER_TYPES.dinein && tableNumber && ((0, jsx_runtime_1.jsxs)("span", __assign({ className: "table-success-header-type" }, { children: [t('Table'), ": ", tableNumber] })))] })));
};
exports.Header = Header;
