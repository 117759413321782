"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Divider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Divider = function (_a) {
    var _b = _a.height, height = _b === void 0 ? 1 : _b, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? '#EAECF0' : _c, styles = __rest(_a, ["height", "backgroundColor"]);
    return (0, jsx_runtime_1.jsx)("div", { style: __assign({ height: height, backgroundColor: backgroundColor }, styles) });
};
exports.Divider = Divider;
