"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreService = void 0;
var react_1 = require("react");
var react_query_1 = require("@tanstack/react-query");
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var store_1 = require("../../store");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var httpClient_1 = require("../httpClient");
var StoreService = (function () {
    function StoreService() {
    }
    StoreService.useGetStore = function () {
        var t = (0, react_i18next_1.useTranslation)().t;
        var history = (0, react_router_1.useHistory)();
        var storeId = (0, react_router_1.useParams)().storeId;
        var dispatch = (0, react_redux_1.useDispatch)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var orderType = (0, hooks_1.useSelector)(function (state) { return state.checkout.orderType; });
        var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
        var notes = (0, hooks_1.useSelector)(function (state) { return state.checkout.notes; });
        var isCutleryOn = (0, hooks_1.useSelector)(function (state) { return state.checkout.cutleryCount; });
        var isFutureDate = (0, hooks_1.useSelector)(function (state) { return state.checkout.isFutureDate; });
        var _a = (0, react_query_1.useQuery)(['store', storeId], function () { return (0, httpClient_1.getData)("/restaurants/get", { params: { restaurant_id: storeId } }); }, {
            onError: function () {
                history.push({ pathname: "/store" });
                react_toastify_1.toast.error('Something wrong');
            },
        }), _b = _a.data, store = _b === void 0 ? {} : _b, isStoreLoading = _a.isInitialLoading;
        var chain = store.chain || {};
        var mainProvider = chain.main_payment_provider || {};
        var additionalProviders = chain.additional_payment_providers || [];
        var providers = __spreadArray([mainProvider], __read(additionalProviders), false);
        (0, react_1.useEffect)(function () {
            if (!isStoreLoading) {
                var isDineIn = orderType === utils_1.ORDER_TYPES.dinein;
                var isPickup = orderType === utils_1.ORDER_TYPES.pickup;
                var isDelivery = orderType === utils_1.ORDER_TYPES.delivery;
                var isStoreOpened = store.is_open;
                if (!isDineIn && !isDelivery && !isPickup) {
                    history.push({ pathname: "/store" });
                    return;
                }
                if ((isDelivery && !store.has_delivery) ||
                    (isDineIn && !store.has_dine_in) ||
                    (isPickup && !store.has_takeaway)) {
                    history.push({ pathname: "/store" });
                    return;
                }
                if ((isDineIn && !isStoreOpened) || (isPickup && !isStoreOpened && !isFutureDate)) {
                    history.push({ pathname: "/store" });
                    queryClient.removeQueries(['store']);
                    react_toastify_1.toast.error(t('Restaurant is closed'));
                    return;
                }
                dispatch((0, store_1.changeCachedStoreId)(store.id));
                if (!store.tips_allowed) {
                    dispatch((0, store_1.changeTipPercent)(null));
                    dispatch((0, store_1.changeTipAmount)(null));
                }
                if (!store.cutlery_allowed && isCutleryOn) {
                    dispatch((0, store_1.changeCutlery)(null));
                }
                if (!store.order_notes_allowed && notes) {
                    dispatch((0, store_1.changeNotes)(''));
                }
            }
        }, [isStoreLoading]);
        (0, react_1.useEffect)(function () {
            if (!isStoreLoading) {
                var _a = (0, utils_1.checkProviders)(providers), isStripe = _a.isStripe, isAmeria = _a.isAmeria, isIdram = _a.isIdram, isForabank = _a.isForabank;
                dispatch((0, store_1.changeSaveCard)(false));
                dispatch((0, store_1.changeAmeriaCardBindingUuid)(''));
                if (isAmeria) {
                    dispatch((0, store_1.changePaymentTab)(utils_1.PAYMENT_TABS.ameriaNewCard));
                    return;
                }
                if (isStripe) {
                    if (paymentTab === utils_1.PAYMENT_TABS.stripeAppleGoogle ||
                        paymentTab === utils_1.PAYMENT_TABS.stripeCard) {
                        return;
                    }
                    dispatch((0, store_1.changePaymentTab)(utils_1.PAYMENT_TABS.stripeAppleGoogle));
                    return;
                }
                if (isIdram && !isAmeria) {
                    dispatch((0, store_1.changePaymentTab)(utils_1.PAYMENT_TABS.idram));
                    return;
                }
                if (isForabank) {
                    if (paymentTab === utils_1.PAYMENT_TABS.forabankSBP ||
                        paymentTab === utils_1.PAYMENT_TABS.forabankCard) {
                        return;
                    }
                    dispatch((0, store_1.changePaymentTab)(utils_1.PAYMENT_TABS.forabankSBP));
                    return;
                }
                dispatch((0, store_1.changePaymentTab)(''));
            }
        }, [isStoreLoading]);
        return {
            store: store,
            chain: chain,
            providers: providers,
            isStoreLoading: isStoreLoading,
        };
    };
    return StoreService;
}());
exports.StoreService = StoreService;
__exportStar(require("./types"), exports);
