"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollToTop = void 0;
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var ScrollToTop = function () {
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    (0, react_1.useEffect)(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);
    return null;
};
exports.ScrollToTop = ScrollToTop;
