"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pencil = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Pencil = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 18 : _b, _c = _a.fill, fill = _c === void 0 ? 'black' : _c, props = __rest(_a, ["size", "fill"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 19 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M5.07775 11.9175L13.1068 4.31104L11.9874 3.25054L3.95833 10.857V11.9175H5.07775ZM5.73404 13.4175H2.375V10.2353L11.4277 1.65904C11.5762 1.51844 11.7775 1.43945 11.9874 1.43945C12.1973 1.43945 12.3987 1.51844 12.5471 1.65904L14.7867 3.78079C14.9352 3.92144 15.0185 4.11217 15.0185 4.31104C15.0185 4.50992 14.9352 4.70065 14.7867 4.84129L5.73404 13.4175ZM2.375 14.9175H16.625V16.4175H2.375V14.9175Z", fill: fill }) })));
};
exports.Pencil = Pencil;
