"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetAmounts = void 0;
var useRedux_1 = require("./useRedux");
var services_1 = require("../services");
var utils_1 = require("../utils");
var useGetAmounts = function () {
    var _a = services_1.StoreService.useGetStore(), store = _a.store, isStoreLoading = _a.isStoreLoading;
    var chain = store.chain || {};
    var checkoutProducts = (0, useRedux_1.useSelector)(function (state) { return state.checkout.products; });
    var orderType = (0, useRedux_1.useSelector)(function (state) { return state.checkout.orderType; });
    var tipPercent = (0, useRedux_1.useSelector)(function (state) { return state.checkout.tipPercent; });
    var tipAmount = (0, useRedux_1.useSelector)(function (state) { return state.checkout.tipAmount; });
    var serviceFeePercent = chain.cheqit_fee_percent;
    var isDineIn = orderType === utils_1.ORDER_TYPES.dinein;
    var isCheckoutEmpty = checkoutProducts.length === 0;
    var subtotal = 0;
    var vat = 0;
    var tip = 0;
    var serviceFee = 0;
    var serviceCharge = 0;
    if (!isCheckoutEmpty) {
        subtotal = (0, utils_1.calculateBasketSubtotal)(checkoutProducts);
        vat = (0, utils_1.calculateNotIncludedInProductVAT)(checkoutProducts);
    }
    if (isDineIn && store.service_charge_percent != null) {
        serviceCharge = (subtotal + vat) * (store.service_charge_percent / 100);
        if (store.service_charge_round != null && store.service_charge_round > 0) {
            serviceCharge =
                Math.ceil(serviceCharge / store.service_charge_round) * store.service_charge_round;
        }
    }
    if (serviceFeePercent) {
        serviceFee += ((subtotal + vat) * serviceFeePercent) / 100;
    }
    if (tipPercent) {
        tip = (subtotal + serviceCharge + vat) * (tipPercent / 100);
    }
    if (tipAmount) {
        tip = tipAmount;
    }
    var total = subtotal + tip + serviceCharge + vat + serviceFee;
    return {
        total: isStoreLoading ? 0 : total,
        subtotal: subtotal,
        vat: vat,
        serviceFee: serviceFee,
        serviceFeePercent: serviceFeePercent,
        serviceCharge: serviceCharge,
        tip: tip,
        isStoreLoading: isStoreLoading
    };
};
exports.useGetAmounts = useGetAmounts;
