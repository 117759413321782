"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentService = void 0;
var react_1 = require("react");
var react_router_1 = require("react-router");
var react_query_1 = require("@tanstack/react-query");
var react_toastify_1 = require("react-toastify");
var react_stripe_js_1 = require("@stripe/react-stripe-js");
var react_redux_1 = require("react-redux");
var react_i18next_1 = require("react-i18next");
var types_1 = require("./types");
var httpClient_1 = require("../httpClient");
var utils_1 = require("../../utils");
var hooks_1 = require("../../hooks");
var store_1 = require("../../store");
var PaymentService = (function () {
    function PaymentService() {
    }
    PaymentService.useGetPayment = function (paymentId) {
        var _this = this;
        var dispatch = (0, react_redux_1.useDispatch)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var count = 0;
        var timeout = function (cb, ms) { return setTimeout(cb, ms); };
        var _b = (0, react_query_1.useQuery)(['payment', paymentId], function () {
            return (0, httpClient_1.getData)('payments/get', { params: { key: paymentId } }).then(function (payment) {
                var _b;
                if (payment.isIIko && !((_b = payment.order) === null || _b === void 0 ? void 0 : _b.pos_receipt_id) && count < 5) {
                    count++;
                    throw new Error();
                }
                return payment;
            });
        }, {
            retry: 20,
            retryDelay: 4000,
            enabled: !!paymentId,
            onSuccess: function (payment) { return __awaiter(_this, void 0, void 0, function () {
                var _b, _c;
                return __generator(this, function (_d) {
                    if (payment.status === 'draft' && count < 20) {
                        timeout(function () {
                            queryClient.invalidateQueries(['payment', paymentId]);
                        }, 1000 * 4);
                        count++;
                    }
                    else if (((_b = payment.order) === null || _b === void 0 ? void 0 : _b.type) === utils_1.ORDER_TYPES.pickup &&
                        ((_c = payment.order) === null || _c === void 0 ? void 0 : _c.status) !== utils_1.ORDER_STATUSES.completed) {
                        timeout(function () {
                            queryClient.invalidateQueries(['payment', paymentId]);
                        }, 1000 * 20);
                    }
                    if (payment.status === 'accepted') {
                        dispatch((0, store_1.clearCheckoutAfterPayment)());
                    }
                    return [2];
                });
            }); },
        }), _c = _b.data, payment = _c === void 0 ? {} : _c, isPaymentLoading = _b.isInitialLoading;
        return {
            payment: payment,
            isPaymentLoading: isPaymentLoading,
        };
    };
    PaymentService.usePaymentIntent = function () {
        return (0, react_query_1.useMutation)(['payment-intent'], function (data) {
            return httpClient_1.httpClient.post(utils_1.PAYMENT_INTENT_ENDPOINT, data).then(function (res) {
                var _b;
                if ((_b = res.data) === null || _b === void 0 ? void 0 : _b.is_demo) {
                    window.location.href = "/store/confirm/".concat(res.data.key);
                    return;
                }
                return res.data;
            });
        });
    };
    PaymentService.usePreparePayment = function () {
        var storeId = (0, react_router_1.useParams)().storeId;
        var _b = this.usePaymentIntent(), pay = _b.mutate, isPaymentProcessing = _b.isLoading;
        var products = (0, hooks_1.useSelector)(function (state) { return state.checkout.products; });
        var orderType = (0, hooks_1.useSelector)(function (state) { return state.checkout.orderType; });
        var tipPercent = (0, hooks_1.useSelector)(function (state) { return state.checkout.tipPercent; });
        var tipAmount = (0, hooks_1.useSelector)(function (state) { return state.checkout.tipAmount; });
        var pickupAt = (0, hooks_1.useSelector)(function (state) { return state.checkout.pickupAt; });
        var customerDetails = (0, hooks_1.useSelector)(function (state) { return state.checkout.customerDetails; });
        var cutleryCount = (0, hooks_1.useSelector)(function (state) { return state.checkout.cutleryCount; });
        var tableNumber = (0, hooks_1.useSelector)(function (state) { return state.checkout.tableNumber; });
        var notes = (0, hooks_1.useSelector)(function (state) { return state.checkout.notes; });
        var ameriaCardBindingUuid = (0, hooks_1.useSelector)(function (state) { return state.checkout.ameriaCardBindingUuid; });
        var saveCard = (0, hooks_1.useSelector)(function (state) { return state.checkout.saveCard; });
        var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
        var request = {
            cart: {
                items: products,
                tipPercent: tipPercent,
                tipAmount: tipAmount,
                pickupAt: pickupAt,
                table: tableNumber,
                notes: notes,
                cutleryCount: cutleryCount,
            },
            type: orderType,
            customerDetails: customerDetails,
            bindingCardUuid: ameriaCardBindingUuid,
            restaurant_id: storeId,
            method_type: paymentTab === utils_1.PAYMENT_TABS.stripeAppleGoogle ? 'apple' : 'card',
            saveCard: saveCard,
        };
        if (paymentTab === utils_1.PAYMENT_TABS.forabankSBP) {
            request.method_type = 'sbp';
        }
        if (paymentTab === utils_1.PAYMENT_TABS.idram) {
            request.provider = types_1.IPaymentProvider.Idram;
        }
        return {
            pay: pay,
            request: request,
            isPaymentProcessing: isPaymentProcessing,
        };
    };
    PaymentService.useStripeCardPay = function (stripe, elements) {
        var _this = this;
        var history = (0, react_router_1.useHistory)();
        var _b = __read((0, react_1.useState)(false), 2), isCardPayProcessing = _b[0], setIsCardPayProcessing = _b[1];
        var submit = function (clientSecret, key) { return __awaiter(_this, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsCardPayProcessing(true);
                        if (!elements) {
                            return [2];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, , 3, 4]);
                        return [4, (stripe === null || stripe === void 0 ? void 0 : stripe.confirmCardPayment(clientSecret, {
                                payment_method: {
                                    card: elements.getElement(react_stripe_js_1.CardElement),
                                },
                            }))];
                    case 2:
                        payload = _b.sent();
                        if (payload === null || payload === void 0 ? void 0 : payload.error) {
                            react_toastify_1.toast.error("Payment failed ".concat(payload.error.message));
                        }
                        else {
                            history.push("/store/confirm/".concat(key));
                        }
                        return [3, 4];
                    case 3:
                        setIsCardPayProcessing(false);
                        return [7];
                    case 4: return [2];
                }
            });
        }); };
        return {
            submit: submit,
            isCardPayProcessing: isCardPayProcessing,
        };
    };
    var _a;
    _a = PaymentService;
    PaymentService.useFormPay = function (link) {
        var _b = __read((0, react_1.useState)(false), 2), isFormPaymentProcessing = _b[0], setIsFormPaymentProcessing = _b[1];
        var formSubmit = function (data) {
            setIsFormPaymentProcessing(true);
            var form = document.createElement('form');
            form.style.display = 'none';
            form.method = 'POST';
            form.action = link;
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    var hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = data[key];
                    form.appendChild(hiddenField);
                }
            }
            document.body.appendChild(form);
            form.submit();
            setIsFormPaymentProcessing(false);
            document.removeChild(form);
        };
        return {
            formSubmit: formSubmit,
            isFormPaymentProcessing: isFormPaymentProcessing,
        };
    };
    PaymentService.useCheckPayment = function (_b) {
        var paymentId = _b.paymentId, handlePaymentCanceled = _b.handlePaymentCanceled, handlePaymentAccepted = _b.handlePaymentAccepted;
        var history = (0, react_router_1.useHistory)();
        var queryClient = (0, react_query_1.useQueryClient)();
        var dispatch = (0, react_redux_1.useDispatch)();
        var t = (0, react_i18next_1.useTranslation)().t;
        var timeout = function (cb, ms) { return setTimeout(cb, ms); };
        var _c = (0, react_query_1.useQuery)(['check-payment', paymentId], function () { return (0, httpClient_1.getData)('payments/get', { params: { key: paymentId } }); }, {
            enabled: !!paymentId,
            onSuccess: function (payment) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(_a, function (_b) {
                    if (payment.status === 'draft') {
                        timeout(function () {
                            queryClient.invalidateQueries(['check-payment', paymentId]);
                        }, 1000 * 8);
                    }
                    else if (payment.status === 'accepted') {
                        dispatch((0, store_1.clearCheckoutAfterPayment)());
                        history.push("/store/confirm/".concat(payment.payment_key));
                        if (handlePaymentAccepted) {
                            handlePaymentAccepted(payment);
                        }
                    }
                    else if (payment.status === 'canceled') {
                        react_toastify_1.toast.error(t('Payment Error'));
                        if (handlePaymentCanceled) {
                            handlePaymentCanceled(payment);
                        }
                    }
                    return [2];
                });
            }); },
        }), _d = _c.data, payment = _d === void 0 ? {} : _d, isPaymentChecking = _c.isInitialLoading;
        return {
            payment: payment,
            isPaymentChecking: isPaymentChecking,
        };
    };
    return PaymentService;
}());
exports.PaymentService = PaymentService;
__exportStar(require("./types"), exports);
