"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlusSecondary = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PlusSecondary = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 42 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 42 42", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M21 0C9.40313 0 0 9.40313 0 21C0 32.5969 9.40313 42 21 42C32.5969 42 42 32.5969 42 21C42 9.40313 32.5969 0 21 0ZM30 22.125C30 22.3312 29.8312 22.5 29.625 22.5H22.5L22.5 29.625C22.5 29.8312 22.3312 30 22.125 30H19.875C19.6688 30 19.5 29.8312 19.5 29.625L19.5 22.5H12.375C12.1687 22.5 12 22.3312 12 22.125V19.875C12 19.6688 12.1687 19.5 12.375 19.5H19.5V12.375C19.5 12.1687 19.6688 12 19.875 12H22.125C22.3312 12 22.5 12.1687 22.5 12.375V19.5H29.625C29.8312 19.5 30 19.6688 30 19.875V22.125Z" }) })));
};
exports.PlusSecondary = PlusSecondary;
