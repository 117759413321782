"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var LogoutIcon = function () { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "15", cy: "15", r: "15", fill: "#F9FAFB" }), (0, jsx_runtime_1.jsx)("path", { d: "M8.95833 21.625C8.52292 21.625 8.15031 21.4701 7.8405 21.1603C7.53069 20.8505 7.37553 20.4776 7.375 20.0417V8.95833C7.375 8.52292 7.53017 8.15031 7.8405 7.8405C8.15083 7.53069 8.52344 7.37553 8.95833 7.375H14.5V8.95833H8.95833V20.0417H14.5V21.625H8.95833ZM17.6667 18.4583L16.5781 17.3104L18.5969 15.2917H12.125V13.7083H18.5969L16.5781 11.6896L17.6667 10.5417L21.625 14.5L17.6667 18.4583Z", fill: "black" })] }))); };
exports.LogoutIcon = LogoutIcon;
