"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Languages = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var i18n_1 = require("../../lang/i18n");
var hooks_1 = require("../../hooks");
var Icons_1 = require("../Icons");
require("./index.scss");
var Languages = function () {
    var i18n = (0, react_i18next_1.useTranslation)().i18n;
    var languagesRef = (0, react_1.useRef)(null);
    var _a = __read((0, react_1.useState)(false), 2), isPopupOpened = _a[0], setIsPopOpened = _a[1];
    var selectedLanguage = i18n.language;
    var languages = i18n_1.supportedLngs.filter(function (lng) { return lng !== selectedLanguage; });
    var onChangeLanguage = function (lng) {
        i18n.changeLanguage(lng);
        location.reload();
    };
    var togglePopup = function () { return setIsPopOpened(function (prev) { return !prev; }); };
    (0, hooks_1.useOnClickOutside)(languagesRef, function () { return setIsPopOpened(false); });
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "languages-container", onClick: togglePopup, ref: languagesRef }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "languages-container-item" }, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: selectedLanguage }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "languages-container-items" }, { children: isPopupOpened &&
                    languages.map(function (language) { return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "languages-container-item", onClick: function () { return onChangeLanguage(language); } }, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: language }) }), language)); }) }))] })));
};
exports.Languages = Languages;
