"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notes = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_redux_1 = require("react-redux");
var components_1 = require("../../../components");
var hooks_1 = require("../../../hooks");
var store_1 = require("../../../store");
require("./index.scss");
var Notes = function () {
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var notes = (0, hooks_1.useSelector)(function (state) { return state.checkout.notes; });
    var onChangeNotes = function (e) {
        dispatch((0, store_1.changeNotes)(e.target.value));
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-notes" }, { children: (0, jsx_runtime_1.jsx)(components_1.InputWithIcon, { name: "notes", type: "text", defaultValue: notes || '', icon: (0, jsx_runtime_1.jsx)(components_1.Icon, { name: "notes", size: 24 }), placeholder: t('Add a note'), onChange: onChangeNotes }) })));
};
exports.Notes = Notes;
