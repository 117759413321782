"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PossibleCashback = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../utils");
var hooks_1 = require("../../../hooks");
var Icons_1 = require("../../Icons");
require("./index.scss");
var PossibleCashback = function (_a) {
    var possibleCashback = _a.possibleCashback, cashbackPercent = _a.cashbackPercent, currency = _a.currency;
    var t = (0, react_i18next_1.useTranslation)().t;
    var setFilters = (0, hooks_1.useSetFilters)();
    var onOpenProfile = function () { return setFilters({ profile: 'opened' }); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "possible-cashback-item table-subtotal-item table-subtotal-total" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "possible-cashback-item-percent" }, { children: [(0, jsx_runtime_1.jsxs)("span", { children: [t('Cashback'), " (", cashbackPercent, "%)"] }), (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "present" })] })), (0, jsx_runtime_1.jsxs)("span", { children: [currency, (0, utils_1.round)(possibleCashback)] })] })), (0, jsx_runtime_1.jsxs)("span", __assign({ className: "possible-cashback-item-helper" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ style: { textDecoration: 'underline', cursor: 'pointer', color: '#555c6e' }, onClick: onOpenProfile }, { children: t('Sign up') })), ' ', (0, jsx_runtime_1.jsx)("span", { children: t('cashback.sign_up_to_earn', { percent: cashbackPercent }) })] }))] }));
};
exports.PossibleCashback = PossibleCashback;
