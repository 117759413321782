"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveX = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var RemoveX = function () { return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M6.969 6.96936C7.03867 6.89952 7.12143 6.8441 7.21255 6.80629C7.30366 6.76849 7.40135 6.74902 7.5 6.74902C7.59865 6.74902 7.69633 6.76849 7.78745 6.80629C7.87857 6.8441 7.96133 6.89952 8.031 6.96936L12 10.9399L15.969 6.96936C16.0387 6.89963 16.1215 6.84432 16.2126 6.80658C16.3037 6.76884 16.4014 6.74942 16.5 6.74942C16.5986 6.74942 16.6963 6.76884 16.7874 6.80658C16.8785 6.84432 16.9613 6.89963 17.031 6.96936C17.1007 7.0391 17.156 7.12188 17.1938 7.21299C17.2315 7.3041 17.2509 7.40175 17.2509 7.50036C17.2509 7.59898 17.2315 7.69663 17.1938 7.78774C17.156 7.87885 17.1007 7.96163 17.031 8.03136L13.0605 12.0004L17.031 15.9694C17.1007 16.0391 17.156 16.1219 17.1938 16.213C17.2315 16.3041 17.2509 16.4017 17.2509 16.5004C17.2509 16.599 17.2315 16.6966 17.1938 16.7877C17.156 16.8788 17.1007 16.9616 17.031 17.0314C16.9613 17.1011 16.8785 17.1564 16.7874 17.1941C16.6963 17.2319 16.5986 17.2513 16.5 17.2513C16.4014 17.2513 16.3037 17.2319 16.2126 17.1941C16.1215 17.1564 16.0387 17.1011 15.969 17.0314L12 13.0609L8.031 17.0314C7.96127 17.1011 7.87848 17.1564 7.78737 17.1941C7.69626 17.2319 7.59861 17.2513 7.5 17.2513C7.40138 17.2513 7.30373 17.2319 7.21262 17.1941C7.12151 17.1564 7.03873 17.1011 6.969 17.0314C6.89927 16.9616 6.84395 16.8788 6.80621 16.7877C6.76847 16.6966 6.74905 16.599 6.74905 16.5004C6.74905 16.4017 6.76847 16.3041 6.80621 16.213C6.84395 16.1219 6.89927 16.0391 6.969 15.9694L10.9395 12.0004L6.969 8.03136C6.89915 7.9617 6.84374 7.87893 6.80593 7.78781C6.76812 7.6967 6.74866 7.59901 6.74866 7.50036C6.74866 7.40171 6.76812 7.30403 6.80593 7.21291C6.84374 7.1218 6.89915 7.03903 6.969 6.96936Z", fill: "black" }) }))); };
exports.RemoveX = RemoveX;
