"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorWrapper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Icons_1 = require("../Icons");
require("./index.scss");
var ErrorWrapper = function (_a) {
    var errorTitle = _a.errorTitle, errorDescription = _a.errorDescription;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "error-wrapper-container" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "error-wrapper-error_icon" }, { children: (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "error", width: 100 }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "error-wrapper-title" }, { children: errorTitle })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "error-wrapper-title_helper" }, { children: errorDescription }))] })));
};
exports.ErrorWrapper = ErrorWrapper;
