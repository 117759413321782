"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetGeolocation = void 0;
var react_1 = require("react");
var utils_1 = require("../utils");
var useGetGeolocation = function () {
    var _a = __read((0, react_1.useState)(null), 2), coordinates = _a[0], setCoordinates = _a[1];
    (0, react_1.useEffect)(function () {
        if (!!navigator.geolocation.getCurrentPosition) {
            (0, utils_1.getUserGeolocation)(function (coordinates) {
                setCoordinates(coordinates);
            });
        }
    }, [navigator.geolocation]);
    return { coordinates: coordinates };
};
exports.useGetGeolocation = useGetGeolocation;
