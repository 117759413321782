"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwipeUpModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Modal_1 = require("../Modal");
require("./index.scss");
var SwipeUpModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, children = _a.children;
    return ((0, jsx_runtime_1.jsx)(Modal_1.Modal, __assign({ className: "pickup-schedule-modal", closeTimeoutMS: 300, onRequestClose: onClose, isOpen: isOpen }, { children: children })));
};
exports.SwipeUpModal = SwipeUpModal;
