"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../utils");
require("./index.scss");
var UserCard = function (_a) {
    var chainName = _a.chainName, currency = _a.currency, cashbackBalance = _a.cashbackBalance, loyaltyType = _a.loyaltyType;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "user-card-container" }, { children: [(0, jsx_runtime_1.jsxs)("h4", __assign({ className: "user-card-name" }, { children: [chainName, "\u00A0", t(loyaltyType)] })), (0, jsx_runtime_1.jsxs)("h4", __assign({ className: "user-card-remaining" }, { children: [currency, (0, utils_1.round)(cashbackBalance)] }))] })));
};
exports.UserCard = UserCard;
