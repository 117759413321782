"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StarRating = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Icons_1 = require("../Icons");
require("./index.scss");
var StarRating = function (_a) {
    var rating = _a.rating, _b = _a.size, size = _b === void 0 ? 20 : _b, _c = _a.starsCount, starsCount = _c === void 0 ? 5 : _c, _d = _a.gap, gap = _d === void 0 ? 2 : _d, _e = _a.outlineColor, outlineColor = _e === void 0 ? '#6172F3' : _e, _f = _a.fillColor, fillColor = _f === void 0 ? '#6172F3' : _f, onSetRating = _a.onSetRating;
    var _g = __read((0, react_1.useState)(null), 2), hover = _g[0], setHover = _g[1];
    var stars = __spreadArray([], __read(Array(starsCount)), false);
    var startPaddings = { paddingRight: gap, paddingLeft: gap };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "star-rating" }, { children: stars.map(function (_, star) {
            star += 1;
            return ((0, jsx_runtime_1.jsx)("span", __assign({ className: "star-rating-star", onClick: function () { return onSetRating(star); }, onMouseEnter: function () { return setHover(star); }, onMouseLeave: function () { return setHover(rating); } }, { children: star <= (hover || rating || 0) ? ((0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "star", size: size, fill: fillColor, style: startPaddings })) : ((0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "reg-star", size: size, fill: outlineColor, style: startPaddings })) }), star));
        }) })));
};
exports.StarRating = StarRating;
