"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Categories = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var services_1 = require("../../../services");
var hooks_1 = require("../../../hooks");
var CategoryProduct_1 = require("./CategoryProduct");
require("./index.scss");
var Categories = function (_a) {
    var currency = _a.currency, categories = _a.categories, categoriesRef = _a.categoriesRef;
    var onGoToProductPage = (0, hooks_1.useNavigate)().onGoToProductPage;
    var fetchProduct = services_1.ProductService.useFetchProduct().fetchProduct;
    var goToProductPage = (0, react_1.useCallback)(function (productId, basketProduct, from) {
        onGoToProductPage(productId, basketProduct, from);
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "catalog-page-categories" }, { children: categories.map(function (category, categoryIndex) { return ((0, jsx_runtime_1.jsxs)("section", __assign({ className: "catalog-page-category", ref: function (el) { return (categoriesRef.current[categoryIndex] = el); } }, { children: [(0, jsx_runtime_1.jsx)("h2", __assign({ className: "catalog-page-category-title" }, { children: category.name })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "catalog-page-category-products" }, { children: category.dishes.map(function (product) { return ((0, jsx_runtime_1.jsx)(CategoryProduct_1.CategoryProduct, { id: product.id, title: product.title, photo: product.photo.thumb, price: product.price, stockLimit: product.stock_limit, stockStatus: product.stock_status, isModifiersEmpty: product.is_modifiers_empty, currency: currency, goToProductPage: goToProductPage, fetchProduct: fetchProduct }, product.id)); }) }))] }), category.id)); }) })));
};
exports.Categories = Categories;
