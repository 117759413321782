"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductModifiers = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_1 = require("react-router");
var react_toastify_1 = require("react-toastify");
var react_i18next_1 = require("react-i18next");
var store_1 = require("../../../store");
var hooks_1 = require("../../../hooks");
var utils_1 = require("../../../utils");
var components_1 = require("../../../components");
var ModifierCategory_1 = require("./ModifierCategory");
require("./index.scss");
var ProductModifiers = function (_a) {
    var currency = _a.currency;
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, react_redux_1.useDispatch)();
    var historyState = (0, react_router_1.useLocation)().state;
    var _b = (0, hooks_1.useNavigate)(), onGoToCheckoutPage = _b.onGoToCheckoutPage, onGoBack = _b.onGoBack;
    var product = (0, hooks_1.useSelector)(function (state) { return state.product; });
    var basketProduct = historyState === null || historyState === void 0 ? void 0 : historyState.basketProduct;
    var historyFrom = historyState === null || historyState === void 0 ? void 0 : historyState.from;
    var productPrice = (0, utils_1.calculateProductSubtotal)(product);
    var quantity = product.quantity;
    var productModifiers = product.modifier_groups || [];
    var isMinusDisabled = quantity <= 1;
    var isOutOfStock = false;
    var onAdd = function () {
        var warningModifier = (0, utils_1.findWarningModifier)(product);
        if (warningModifier) {
            dispatch((0, store_1.changeWarningModifierId)(warningModifier.id));
            var modifier = document.getElementById("modifier-".concat(warningModifier.id));
            modifier === null || modifier === void 0 ? void 0 : modifier.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        if (basketProduct) {
            dispatch((0, store_1.updateProductFromBasket)(product));
            if (historyFrom === 'catalog') {
                onGoBack();
            }
            else if (historyFrom === 'checkout') {
                onGoToCheckoutPage();
            }
            react_toastify_1.toast.success((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("strong", { children: product.title }), " ", t('updated')] }), {
                className: 'toast-product',
                hideProgressBar: true,
                closeButton: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}),
                icon: false,
                transition: react_toastify_1.Zoom,
            });
            return;
        }
        dispatch((0, store_1.addProductToBasket)(product));
        onGoBack();
        react_toastify_1.toast.success((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("strong", { children: product.title }), " ", t('added to order')] }), {
            className: 'toast-product',
            hideProgressBar: true,
            closeButton: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}),
            icon: false,
            transition: react_toastify_1.Zoom,
        });
    };
    var increase = function () { return dispatch((0, store_1.increaseProductQuantity)()); };
    var decrease = function () { return dispatch((0, store_1.decreaseProductQuantity)()); };
    (0, react_1.useEffect)(function () {
        return function () {
            dispatch((0, store_1.clearProduct)());
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (product.warningModifierId) {
            var warningModifier = (0, utils_1.findWarningModifier)(product);
            if (!warningModifier) {
                dispatch((0, store_1.changeWarningModifierId)(null));
            }
        }
    }, [product]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-modifiers" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "product-page-modifiers-container" }, { children: productModifiers.map(function (modifier, modifierCategoryIndex) { return ((0, jsx_runtime_1.jsxs)(react_1.default.Fragment, { children: [(0, jsx_runtime_1.jsx)(ModifierCategory_1.ModifierCategory, { modifierCategoryIndex: modifierCategoryIndex, modifier: modifier }, modifier.id), productModifiers.length - 1 !== modifierCategoryIndex && ((0, jsx_runtime_1.jsx)(components_1.Divider, { height: 6, color: "#D0D5DD" }))] }, modifier.id)); }) })), (0, jsx_runtime_1.jsx)(components_1.FooterFixedContainer, __assign({ className: "footer-buttons" }, { children: isOutOfStock ? ((0, jsx_runtime_1.jsx)(components_1.Button, __assign({ color: "gray-100", disabled: true }, { children: t('Sold Out') }))) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.MultipleSelectPrimary, { className: "product-page-multiple-select", quantity: quantity, isMinusDisabled: isMinusDisabled, isPlusDisabled: false, onClickPlus: increase, onClickMinus: decrease }), (0, jsx_runtime_1.jsxs)(components_1.Button, __assign({ fontWeight: "bold", className: "product-page-footer-button product-page-footer-button-add", onClick: onAdd }, { children: [(0, jsx_runtime_1.jsx)("span", { children: t(basketProduct ? 'Update' : 'Add') }), !!productPrice && (0, jsx_runtime_1.jsx)("span", { children: (0, utils_1.getPrice)({ price: productPrice, currency: currency }) })] }))] })) }))] })));
};
exports.ProductModifiers = ProductModifiers;
