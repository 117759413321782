"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMapsAddressType = void 0;
var IMapsAddressType;
(function (IMapsAddressType) {
    IMapsAddressType["subpremise"] = "subpremise";
    IMapsAddressType["street_number"] = "street_number";
    IMapsAddressType["route"] = "route";
    IMapsAddressType["locality"] = "locality";
    IMapsAddressType["postal_code"] = "postal_code";
})(IMapsAddressType = exports.IMapsAddressType || (exports.IMapsAddressType = {}));
