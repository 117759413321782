"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_1 = require("react-router");
var react_1 = require("react");
var services_1 = require("../../services");
var hooks_1 = require("../../hooks");
var components_1 = require("../../components");
var ProductHeader_1 = require("./ProductHeader");
var ProductDetails_1 = require("./ProductDetails");
var ProductModifiers_1 = require("./ProductModifiers");
require("./index.scss");
var Product = function () {
    var _a;
    var productId = (0, react_router_1.useParams)().productId;
    var productDetailsRef = (0, react_1.useRef)(null);
    var _b = (0, hooks_1.useGetConfigs)(), currency = _b.currency, isStoreLoading = _b.isStoreLoading;
    var _c = services_1.ProductService.useGetProduct(productId), product = _c.product, isProductLoading = _c.isProductLoading;
    if (isProductLoading || isStoreLoading) {
        return (0, jsx_runtime_1.jsx)(components_1.FullPageLoader, {});
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "product-page" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "product-page-container" }, { children: [(0, jsx_runtime_1.jsx)(ProductHeader_1.ProductHeader, { productName: product.title, productPhoto: (_a = product.photo) === null || _a === void 0 ? void 0 : _a.big_thumb_url, productDetailsRef: productDetailsRef }), (0, jsx_runtime_1.jsx)(ProductDetails_1.ProductDetails, { product: product, productDetailsRef: productDetailsRef }), (0, jsx_runtime_1.jsx)(ProductModifiers_1.ProductModifiers, { currency: currency })] })) })));
};
exports.Product = Product;
