"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Banner = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_responsive_carousel_1 = require("react-responsive-carousel");
require("./index.scss");
var Banner = function (_a) {
    var banners = _a.banners;
    var isEmpty = banners.length === 0;
    var isSingle = banners.length === 1;
    if (isEmpty) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "catalog-page-banner" }, { children: (0, jsx_runtime_1.jsx)(react_responsive_carousel_1.Carousel, __assign({ autoPlay: false, showIndicators: !isSingle, showStatus: false, showThumbs: false, transitionTime: 500 }, { children: banners.map(function (banner) { return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("img", { className: "catalog-page-banner-image", alt: "", src: banner.image.url }) }, banner.id)); }) })) })));
};
exports.Banner = Banner;
