"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cooking = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Cooking = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 16 : _b, props = __rest(_a, ["size"]);
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ stroke: "currentColor", fill: "currentColor", strokeWidth: "0", viewBox: "0 0 512 512", height: size, width: size, xmlns: "http://www.w3.org/2000/svg" }, props, { children: (0, jsx_runtime_1.jsx)("path", { d: "M151.7 16.73s-20.6 14.12-22 25.18c-1.4 11.33 17.6 19.24 15.6 30.48-1.3 7.43-16.6 15.38-16.6 15.38s38.7-3.36 42.3-18.3c2.9-11.82-19.8-16.61-23-28.35-2.1-7.94 3.7-24.39 3.7-24.39zm214.4 4.89s-24.8 13.58-24.9 25.45c-.1 7.24 14.4 8.67 14.8 15.9.5 8.56-15.3 20.68-15.3 20.68s33.6-3.81 38.1-16.97c2.7-7.77-9.4-13.81-11.6-21.73-2.1-7.5-1.1-23.33-1.1-23.33zm-106.9.26s-26.9 13.75-24.9 25.45c1.4 7.93 20.6 2.62 21.7 10.6 1.7 13.01-29.6 25.98-29.6 25.98s56.5-1.44 58.8-22.27c1.1-9.88-20-7.79-24.9-16.43-3.9-6.77-1.1-23.33-1.1-23.33zM48 105.6v18h416v-18zm16 37c-14.48 86.9 16.9 138.1 58.6 168.2-3.6-24.8-14.1-49.1-35.06-72.2 39.96 10.5 71.36 48.8 85.36 87.2 2.3-18.8 2.3-27.5 19.5-44.2-3.1 24.8 11.2 26.5 21.2 23.4 25.3-7.9 35.6-39.5 10.6-78.9 47.6 22.7 48.3 48.4 56.3 83.7-2.4-33.2 24.3-46.5 43.7-34-45.1 22.7-8.2 42.2 6.9 47 40 12.8 70-46.3 87.2-91 4.7 19.8.8 39.7-6.5 59.5C441.4 260 459.7 213 448 142.6zm184.3 175.2L75 417.5c2.7 18.4 9 34.4 18.8 48.5l92-44.1-78.7 59.9c3.4 3.4 7.1 6.6 11 9.7l74.7-42.9c0-.7-.1-1.5-.1-2.2 0-37.2 30.5-67.6 67.8-67.6 10.6 0 20.6 2.4 29.5 6.7-2.4-13.4-7.3-27.1-14.8-39.2l-94.9 40.1 82.5-56.5c-4.4-4.5-9.2-8.6-14.5-12.1zm58.9 57.6c1.6 7.2 2.6 14.4 3 21.4l.2 3.9c11.1 12 17.9 28.1 17.9 45.7 0 7.8-1.3 15.3-3.8 22.2l91.4 24.4c4.6-6.3 8.6-12.8 11.8-19.4l-63.1-24.7 70.1 6.9c.9-3 1.6-5.9 2.2-8.9l-97.1-34.3 99.2 15.5c.2-5.8-.1-11.7-.8-17.7zm-46.7 22.1c-27.2 0-49.1 21.8-49.1 48.9 0 27.1 21.9 48.9 49.1 48.9 27.3 0 49.2-21.8 49.2-48.9 0-27.1-21.9-48.9-49.2-48.9zm-4.9 11.8c43.8 0 58.4 71.6 0 71.6 26.6-23.1 29.8-46.9 0-71.6zm.2 9.8c-21.6 17.9-19.3 35.2 0 52-42.4 0-31.8-52 0-52z" }) })));
};
exports.Cooking = Cooking;
