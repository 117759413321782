"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_TABS = exports.GOOGLE_MAPS_API_KEY = exports.PICKUP_VARIATIONS = exports.IDRAM_PAYMENT_INTENT_URL = exports.ORDER_STATUSES = exports.ORDER_TYPES = exports.ORDER_STATUSES_LIST = exports.TIMES = exports.TIPS_PERCENTS = exports.PAYMENT_INTENT_ENDPOINT = void 0;
exports.PAYMENT_INTENT_ENDPOINT = '/order/pay';
exports.TIPS_PERCENTS = [18, 20, 22];
exports.TIMES = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
exports.ORDER_STATUSES_LIST = ['pending', 'confirmed', 'ready', 'completed'];
exports.ORDER_TYPES = {
    dinein: 'dinein',
    pickup: 'pickup',
    delivery: 'delivery',
};
exports.ORDER_STATUSES = {
    pending: 'pending',
    ready: 'ready',
    confirmed: 'confirmed',
    completed: 'completed',
    canceled: 'canceled',
};
exports.IDRAM_PAYMENT_INTENT_URL = 'https://banking.idram.am/Payment/GetPayment';
exports.PICKUP_VARIATIONS = ['Today', 'Future Date'];
exports.GOOGLE_MAPS_API_KEY = 'AIzaSyDKuKcHgFSuTSQ-GW4NPuPQmdEe9TujZLY';
exports.PAYMENT_TABS = {
    stripeAppleGoogle: 'stripeAppleGoogle',
    stripeCard: 'stripeCard',
    ameriaNewCard: 'ameriaNewCard',
    idram: 'idram',
    forabankCard: 'forabank-card',
    forabankSBP: 'forabank-sbp',
};
