"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetFilters = void 0;
var react_1 = require("react");
var react_router_1 = require("react-router");
var useSetFilters = function () {
    var _a = (0, react_router_1.useHistory)(), location = _a.location, push = _a.push, replace = _a.replace;
    return (0, react_1.useCallback)(function (data, isReplace) {
        if (isReplace === void 0) { isReplace = false; }
        var navigate = push;
        if (isReplace) {
            navigate = replace;
        }
        var searchParams = new URLSearchParams(location.search);
        var prevParams = Object.fromEntries(searchParams);
        var newParams = __assign(__assign({}, prevParams), data);
        for (var query in newParams) {
            if (!newParams[query] && newParams[query] !== 0) {
                delete newParams[query];
            }
        }
        var URLParams = new URLSearchParams(newParams);
        navigate({ search: URLParams.toString(), state: location.state });
    }, [location, history]);
};
exports.useSetFilters = useSetFilters;
