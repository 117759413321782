"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payment = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var react_toastify_1 = require("react-toastify");
var services_1 = require("../../../services");
var components_1 = require("../../../components");
var ArmenianPaymentTabs_1 = require("./ArmenianPaymentTabs");
var StripePaymentTabs_1 = require("./StripePaymentTabs");
var ForaBankPayments_1 = require("../ForaBankPayments");
var Terms_1 = require("./Terms");
var utils_1 = require("../../../utils");
var hooks_1 = require("../../../hooks");
require("./index.scss");
var Payment = function (_a) {
    var _b, _c;
    var isValidFields = _a.isValidFields, isAuth = _a.isAuth, onValidate = _a.onValidate, providers = _a.providers, privacyLink = _a.privacyLink, termsLink = _a.termsLink;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _d = services_1.PaymentService.usePreparePayment(), pay = _d.pay, isPaymentProcessing = _d.isPaymentProcessing, request = _d.request;
    var _e = services_1.PaymentService.useFormPay(utils_1.IDRAM_PAYMENT_INTENT_URL), formSubmit = _e.formSubmit, isFormPaymentProcessing = _e.isFormPaymentProcessing;
    var _f = (0, utils_1.checkProviders)(providers), stripeProvider = _f.stripeProvider, isStripe = _f.isStripe, isIdram = _f.isIdram, isAmeria = _f.isAmeria, isForabank = _f.isForabank;
    var stripePromise = (0, hooks_1.useStripePromiseInit)((_b = stripeProvider === null || stripeProvider === void 0 ? void 0 : stripeProvider.options) === null || _b === void 0 ? void 0 : _b.stripePubKey, (_c = stripeProvider === null || stripeProvider === void 0 ? void 0 : stripeProvider.options) === null || _c === void 0 ? void 0 : _c.stripeAccount);
    var paymentTab = (0, hooks_1.useSelector)(function (state) { return state.checkout.paymentTab; });
    var isIdramTabSelected = paymentTab === utils_1.PAYMENT_TABS.idram;
    var isForabankSBP = paymentTab === utils_1.PAYMENT_TABS.forabankSBP;
    var cards = services_1.UserService.useGetBindings(isAuth && isAmeria).cards;
    var isCardsEmpty = cards.length === 0;
    var showPaymentTabs = isStripe || !isCardsEmpty || (isAmeria && isIdram) || isForabank;
    var onPay = function () {
        if (!isValidFields) {
            onValidate();
            return;
        }
        pay(request, {
            onSuccess: function (res) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (isIdramTabSelected) {
                        formSubmit(res);
                    }
                    else if (isForabankSBP && res.data) {
                        window.location = res.data;
                    }
                    else if (res === null || res === void 0 ? void 0 : res.redirect_link) {
                        window.location = res.redirect_link;
                    }
                    return [2];
                });
            }); },
            onError: function (e) {
                var _a, _b;
                var message = ((_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) || 'Please, try again later';
                react_toastify_1.toast.error(message);
            },
        });
    };
    return ((0, jsx_runtime_1.jsxs)(components_1.StripeWrapper, __assign({ isStripe: isStripe, stripeConfig: stripePromise }, { children: [showPaymentTabs && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(components_1.Divider, { height: 6, color: "#EAECF0", margin: "25px -10px" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "checkout-page-subtitle" }, { children: t('Payment') }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "checkout-page-payment" }, { children: [(0, jsx_runtime_1.jsxs)(components_1.PaymentMethods, { children: [isForabank && (0, jsx_runtime_1.jsx)(ForaBankPayments_1.ForaBankPayments, {}), isStripe && (0, jsx_runtime_1.jsx)(StripePaymentTabs_1.StripePaymentTabs, {}), (isAmeria || isIdram) && ((0, jsx_runtime_1.jsx)(ArmenianPaymentTabs_1.ArmenianPaymentTabs, { cards: cards, isCardsEmpty: isCardsEmpty, isAmeria: isAmeria, isIdram: isIdram, isAuth: isAuth }))] }), paymentTab === utils_1.PAYMENT_TABS.stripeCard && (0, jsx_runtime_1.jsx)(components_1.StripeCardElement, {}), (0, jsx_runtime_1.jsx)("div", __assign({ style: { marginTop: 15 } }, { children: isStripe ? ((0, jsx_runtime_1.jsx)(components_1.StripePayment, { isValidFields: isValidFields, onValidate: onValidate })) : ((0, jsx_runtime_1.jsx)(components_1.Button, __assign({ disabled: isPaymentProcessing || isFormPaymentProcessing, onClick: onPay }, { children: t('Place Order') }))) })), (0, jsx_runtime_1.jsx)(Terms_1.Terms, { termsLink: termsLink, privacyLink: privacyLink })] }))] })));
};
exports.Payment = Payment;
