"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentSuccess = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var components_1 = require("../../../components");
var utils_1 = require("../../../utils");
var Header_1 = require("./Header");
var OAPContent_1 = require("./OAPContent");
require("./index.scss");
var PaymentSuccess = function (_a) {
    var _b, _c, _d;
    var payment = _a.payment, onGoBack = _a.onGoBack, user = _a.user;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _e = __read((0, react_1.useState)(true), 2), isFeedbackModalOpened = _e[0], setIsFeedbackModalOpened = _e[1];
    var order = payment.order || {};
    var onCloseFeedbackModal = function () { return setIsFeedbackModalOpened(false); };
    var onOpenFeedbackModal = function () { return setIsFeedbackModalOpened(true); };
    var isFeedbackEnabled = ((_b = payment === null || payment === void 0 ? void 0 : payment.feedback_settings) === null || _b === void 0 ? void 0 : _b.is_collected) &&
        (((_c = payment === null || payment === void 0 ? void 0 : payment.feedback_settings) === null || _c === void 0 ? void 0 : _c.rating_enabled) || ((_d = payment === null || payment === void 0 ? void 0 : payment.feedback_settings) === null || _d === void 0 ? void 0 : _d.review_enabled));
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "table-confirm-centered" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "table-success-container" }, { children: [(0, jsx_runtime_1.jsx)(components_1.Navigation, { isLoggedIn: !!user, title: t('Payment Successful'), className: "table-success-navigation" }), (0, jsx_runtime_1.jsx)(Header_1.Header, { orderId: order.pos_receipt_id || order.id, orderType: order.type, tableNumber: order.table_number, pickupAt: order.pickup_at }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "table-success-price" }, { children: (0, jsx_runtime_1.jsxs)("span", __assign({ className: "table-success-price-paid" }, { children: [t('You paid'), " ", payment.currency, (0, utils_1.round)(payment.amount)] })) })), (0, jsx_runtime_1.jsx)(OAPContent_1.OAPContent, { payment: payment, isFeedbackEnabled: isFeedbackEnabled, onOpenFeedbackModal: onOpenFeedbackModal, onGoBack: onGoBack })] })), (0, jsx_runtime_1.jsx)(components_1.FeedbackModal, { paymentId: payment.id, isOpened: isFeedbackModalOpened && isFeedbackEnabled && !payment.feedback, feedbackSettings: payment.feedback_settings || {}, onClose: onCloseFeedbackModal })] })));
};
exports.PaymentSuccess = PaymentSuccess;
