"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCatalogNavigation = void 0;
var react_1 = require("react");
var useCatalogNavigation = function () {
    var categoriesRef = (0, react_1.useRef)([]);
    var catalogNavigationRef = (0, react_1.useRef)(null);
    var catalogNavigationOffsetLefts = (0, react_1.useRef)([]);
    var onClickOnCategory = function (categoryIndex) {
        var category = categoriesRef.current[categoryIndex];
        var headerOffset = 60;
        var elementPosition = category.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'auto'
        });
    };
    return {
        categoriesRef: categoriesRef,
        catalogNavigationRef: catalogNavigationRef,
        catalogNavigationOffsetLefts: catalogNavigationOffsetLefts,
        onClickOnCategory: onClickOnCategory
    };
};
exports.useCatalogNavigation = useCatalogNavigation;
