"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplyFrom = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var utils_1 = require("../../../utils");
var Icons_1 = require("../../Icons");
var ApplyInput_1 = require("../ApplyInput");
require("./index.scss");
var ApplyFrom = function (_a) {
    var balance = _a.balance, discountAmount = _a.discountAmount, maxAmountCanApplied = _a.maxAmountCanApplied, currency = _a.currency, onApply = _a.onApply, onCancel = _a.onCancel;
    var t = (0, react_i18next_1.useTranslation)().t;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "apply-form" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "apply-form-title" }, { children: [(0, jsx_runtime_1.jsx)("span", { children: t('cashback.add_discount') }), (0, jsx_runtime_1.jsx)(Icons_1.Icon, { name: "present" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "apply-form-description" }, { children: [(0, jsx_runtime_1.jsx)("span", { children: t('cashback.available_balance') }), (0, jsx_runtime_1.jsxs)("span", { children: [currency, (0, utils_1.round)(balance)] })] })), (0, jsx_runtime_1.jsx)(ApplyInput_1.ApplyInput, { onApply: onApply, onCancel: onCancel, currency: currency, discountAmount: discountAmount, maxAmountCanApplied: maxAmountCanApplied, balance: balance })] })));
};
exports.ApplyFrom = ApplyFrom;
