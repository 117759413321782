"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoyaltyIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var LoyaltyIcon = function () { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "15", cy: "15", r: "15", fill: "#F9FAFB" }), (0, jsx_runtime_1.jsx)("path", { d: "M8.95833 14.5H8.16666V20.8333C8.16666 21.2533 8.33348 21.656 8.63041 21.9529C8.92734 22.2499 9.33007 22.4167 9.74999 22.4167H13.7083V14.5H8.95833ZM19.25 14.5H15.2917V22.4167H19.25C19.6699 22.4167 20.0726 22.2499 20.3696 21.9529C20.6665 21.656 20.8333 21.2533 20.8333 20.8333V14.5H19.25ZM19.8762 10.5417C19.9913 10.1565 20.0471 9.7561 20.0417 9.35417C20.0417 7.82625 18.7987 6.58333 17.2708 6.58333C15.9867 6.58333 15.1294 7.75658 14.576 9.02562C14.0305 7.82625 13.1296 6.58333 11.7292 6.58333C10.2012 6.58333 8.95833 7.82625 8.95833 9.35417C8.95833 9.826 9.02087 10.2163 9.12379 10.5417H6.58333V13.7083H13.7083V12.125H15.2917V13.7083H22.4167V10.5417H19.8762ZM10.5417 9.35417C10.5417 8.69946 11.0745 8.16667 11.7292 8.16667C12.4322 8.16667 13.0861 9.37396 13.4692 10.5417H11.3333C11.0372 10.5417 10.5417 10.5417 10.5417 9.35417ZM17.2708 8.16667C17.9255 8.16667 18.4583 8.69946 18.4583 9.35417C18.4583 10.5417 17.9627 10.5417 17.6667 10.5417H15.7057C16.1095 9.294 16.6961 8.16667 17.2708 8.16667Z", fill: "black" })] }))); };
exports.LoyaltyIcon = LoyaltyIcon;
