"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigate = void 0;
var react_router_1 = require("react-router");
var useSetFilters_1 = require("./useSetFilters");
var useNavigate = function () {
    var history = (0, react_router_1.useHistory)();
    var setFilters = (0, useSetFilters_1.useSetFilters)();
    var storeId = (0, react_router_1.useParams)().storeId;
    var searchParams = new URLSearchParams(history.location.search).toString();
    var onGoBack = function () { return history.goBack(); };
    var onGoToCatalogPage = function () {
        history.push({
            pathname: "/store/".concat(storeId),
            search: searchParams,
        });
    };
    var onGoToProductPage = function (productId, basketProduct, from) {
        if (basketProduct) {
            history.push({
                pathname: "/store/".concat(storeId, "/product/").concat(productId),
                search: searchParams,
                state: { basketProduct: basketProduct, from: from },
            });
            return;
        }
        history.push({
            pathname: "/store/".concat(storeId, "/product/").concat(productId),
            search: searchParams,
        });
    };
    var onGoToCheckoutPage = function () {
        history.push({
            pathname: "/store/".concat(storeId, "/checkout"),
            search: searchParams,
        });
    };
    var onOpenProfile = function () { return setFilters({ profile: 'opened' }); };
    return {
        onGoBack: onGoBack,
        onGoToCatalogPage: onGoToCatalogPage,
        onGoToProductPage: onGoToProductPage,
        onGoToCheckoutPage: onGoToCheckoutPage,
        onOpenProfile: onOpenProfile,
    };
};
exports.useNavigate = useNavigate;
