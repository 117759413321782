"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseHistoryIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PurchaseHistoryIcon = function () { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "30", height: "30", viewBox: "0 0 30 30", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "15", cy: "15", r: "15", fill: "#F9FAFB" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 7.49997C8 7.36737 8.05673 7.2402 8.15771 7.14644C8.25869 7.05267 8.39565 7 8.53846 7H21.4615C21.6043 7 21.7413 7.05267 21.8423 7.14644C21.9433 7.2402 22 7.36737 22 7.49997V21.499C22 21.5843 21.9765 21.6681 21.9318 21.7426C21.887 21.8171 21.8225 21.8797 21.7444 21.9244C21.6663 21.9692 21.5771 21.9947 21.4854 21.9985C21.3936 22.0023 21.3024 21.9842 21.2203 21.946L19.3077 21.0591L17.3951 21.947C17.3202 21.9819 17.2376 22 17.1538 22C17.0701 22 16.9875 21.9819 16.9126 21.947L15 21.0591L13.0874 21.947C13.0125 21.9819 12.9299 22 12.8462 22C12.7624 22 12.6798 21.9819 12.6049 21.947L10.6923 21.0591L8.77969 21.947C8.69752 21.9853 8.60617 22.0033 8.51435 21.9995C8.42253 21.9957 8.33329 21.9701 8.25513 21.9252C8.17697 21.8803 8.11249 21.8175 8.06783 21.7429C8.02317 21.6684 7.99982 21.5844 8 21.499V7.49997ZM11.2308 11.9997H13.3846V10.9997H11.2308V11.9997ZM15.5385 11.9997H18.7692V10.9997H15.5385V11.9997ZM13.3846 14.9995H11.2308V13.9995H13.3846V14.9995ZM15.5385 14.9995H18.7692V13.9995H15.5385V14.9995ZM18.7692 17.9993H15.5385V16.9993H18.7692V17.9993Z", fill: "black" })] }))); };
exports.PurchaseHistoryIcon = PurchaseHistoryIcon;
