"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Danger = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Danger = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 25 : _b;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ width: size, height: size, viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M12.4997 2.08301C18.2528 2.08301 22.9163 6.74655 22.9163 12.4997C22.9163 18.2528 18.2528 22.9163 12.4997 22.9163C6.74655 22.9163 2.08301 18.2528 2.08301 12.4997C2.08301 6.74655 6.74655 2.08301 12.4997 2.08301ZM12.4997 15.6247C12.2234 15.6247 11.9585 15.7344 11.7631 15.9298C11.5678 16.1251 11.458 16.3901 11.458 16.6663C11.458 16.9426 11.5678 17.2076 11.7631 17.4029C11.9585 17.5983 12.2234 17.708 12.4997 17.708C12.7759 17.708 13.0409 17.5983 13.2362 17.4029C13.4316 17.2076 13.5413 16.9426 13.5413 16.6663C13.5413 16.3901 13.4316 16.1251 13.2362 15.9298C13.0409 15.7344 12.7759 15.6247 12.4997 15.6247ZM12.4997 6.24967C12.2445 6.24971 11.9983 6.34338 11.8076 6.51292C11.617 6.68246 11.4952 6.91608 11.4653 7.16947L11.458 7.29134V13.5413C11.4583 13.8068 11.56 14.0622 11.7422 14.2553C11.9245 14.4483 12.1736 14.5645 12.4386 14.5801C12.7037 14.5956 12.9647 14.5094 13.1683 14.339C13.3718 14.1686 13.5027 13.9269 13.534 13.6632L13.5413 13.5413V7.29134C13.5413 7.01507 13.4316 6.75012 13.2362 6.55477C13.0409 6.35942 12.7759 6.24967 12.4997 6.24967Z", fill: "#F04438" }) })));
};
exports.Danger = Danger;
