"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetConfigs = void 0;
var services_1 = require("../services");
var useGetConfigs = function () {
    var _a;
    var _b = services_1.StoreService.useGetStore(), store = _b.store, chain = _b.chain, providers = _b.providers, isStoreLoading = _b.isStoreLoading;
    var chainLogo = chain.logo_link;
    var chainName = chain.name;
    var currency = (_a = chain.currency) === null || _a === void 0 ? void 0 : _a.title;
    return {
        store: store,
        chain: chain,
        providers: providers,
        currency: currency,
        chainLogo: chainLogo,
        chainName: chainName,
        isStoreLoading: isStoreLoading
    };
};
exports.useGetConfigs = useGetConfigs;
